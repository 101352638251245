import { Button, LoadPanel, Toast } from "devextreme-react"
import MainHeader from "../../base-components/MainHeader"
import "./css/confedential.css"
import { useState, useEffect } from "react"
import getPersonalData from "../admin/confedential/services/getPersonalData"

const PrivacyPolicy = () => {

    const [policy, setPolicy] = useState(null)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const updatePolicy = async () => {
        setVisibleLoading(true)
        var result = await getPersonalData()
        if (result.success) {
            setPolicy(result.content)
        } 

        setVisibleLoading(false)
    }

    useEffect(() => {
        document.title = "Политика конфиденциальности и обработки персональных данных";
        updatePolicy()
    }, [])

    function createMarkup() {
        return { __html: policy.body };
    }

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <MainHeader />
            <div className="confedential-body-container">
                <div className="caption">
                    Политика конфиденциальности и обработки персональных данных
                </div>
                <div>
                    {
                       policy!== null && policy?.body !== null ? (
                        <div dangerouslySetInnerHTML={createMarkup()}></div>
                       ): (
                        <div>Здесь пока ничего нет</div>
                       )
                    }
                </div>

            </div>



        </div>
    )
}

export default PrivacyPolicy