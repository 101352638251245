import { useEffect, useState } from "react"
import { LoadPanel, Toast, List, Bullet } from 'devextreme-react';
import { Popup, FileUploader, Button } from "devextreme-react"
import { getUserCertificates, isValidSystemSetup, getSystemInfo, createDetachedSignature, createHash } from 'crypto-pro';
import addPdfStampService from "../services/addPdfStampService";
import addSignatureService from "../services/addSignatureService";
import cancelSignatureService from "../services/cancelSignatureService";

const SignDocumentPopup = ({ visible, onHide, request, setRequest, auth }) => {

    const [currentRequest, setCurrentRequest] = useState(null)
    const [sertificates, setSertificates] = useState([])
    const [selectedSertificate, setSelectedSertificate] = useState(null)

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const [plaginStatus, setPlaginStatus] = useState("Загрузка плагина...")
    const [isPluginReady, setIsPluginReady] = useState(false)

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    useEffect(() => {
        if (visible === true) {
            checkInstalledPlugin()
            setCurrentRequest(request)
        }
    }, [visible])

    const onClose = () => {
        setPlaginStatus("Загрузка плагина...")
        setIsPluginReady(false)
        setSertificates([])
        setSelectedSertificate(null)
        setCurrentRequest(null)
        onHide()
    }

    const getSertificates = async () => {
        let serts = await getUserCertificates()
            .then((result) => {
                let newArray = []
                result.forEach(element => {
                    newArray.push({
                        issuerName: element.issuerName,
                        name: element.name,
                        subjectName: element.subjectName,
                        thumbprint: element.thumbprint,
                        validFrom: element.validFrom,
                        validTo: element.validTo
                    })
                });
                setSertificates(newArray)
                setIsPluginReady(true)
            })
            .catch((error) => {
                setPlaginStatus('Не удалось загрузить сертификаты. Проверьте, установлены ли сертификаты в системе');
                setIsPluginReady(false)
            })
    }

    const checkInstalledPlugin = async () => {
        try {
            let isValidSetup = await isValidSystemSetup()
                .then((result) => {
                    if (result) {
                        setPlaginStatus("Загрузка плагина...")
                        setIsPluginReady(false)
                    } else {
                        setPlaginStatus('КриптоПро ЭЦП Browser plug-in не загружен. Проверьте корректность установки плагина, а затем попробуйте подписать заявку в разделе Мои заявки');
                        setIsPluginReady(false)
                        return
                    }
                })
                .catch((error) => {
                    setPlaginStatus("КриптоПро ЭЦП Browser plug-in не загружен. Проверьте корректность установки плагина, а затем попробуйте подписать заявку в разделе Мои заявки")
                    setIsPluginReady(false)
                    return
                })
            const systemInfo = await getSystemInfo()
                .then(async (result) => {
                    setPlaginStatus('Загрузка сертификатов...');
                    await getSertificates()
                })
                .catch((error) => {
                    setPlaginStatus('КриптоПро ЭЦП Browser plug-in не загружен. Проверьте корректность установки плагина, а затем попробуйте подписать заявку в разделе Мои заявки');
                    setIsPluginReady(false)
                    return
                })
        }
        catch (error) {
            setPlaginStatus("КриптоПро ЭЦП Browser plug-in не загружен. Проверьте корректность установки плагина, а затем попробуйте подписать заявку в разделе Мои заявки")
            setIsPluginReady(false)
            return
        }
    }

    const itemRender = (e) => {
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }
        let dateFrom = new Date(e.validFrom)
        let dateTo = new Date(e.validTo)
        return (
            <div>
                <div style={{ wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal', 
                      fontWeight: 'bold',
                      marginBottom: '10px'}}>{e.subjectName}</div>
                <div>Срок действия {dateFrom.toLocaleDateString('ru-RU', options)} - {dateTo.toLocaleDateString('ru-RU', options)}</div>
            </div>
        )
    }

    const onSelectionChanged = (e) => {
        if (e.addedItems?.length !== 0) {
            setSelectedSertificate(e.addedItems[0])
        } else {
            setSelectedSertificate(null)
        }
    }

    const addSignatureHadler = async (documentId, signData) => {

        let body = {
            requestId: currentRequest.requestId,
            documentId: documentId,
            data: signData,
            subjectName: selectedSertificate?.name,
            thumbprint: selectedSertificate?.thumbprint,
            from: selectedSertificate?.validFrom,
            to: selectedSertificate?.validTo
        }
        setVisibleLoading(true)
        var response = await addSignatureService(body, auth)
        if (response.success) {
            setRequest(response.content)
            onClose()
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    const cancelSignHandler = async () => {
        setVisibleLoading(true)
        var response = await cancelSignatureService(currentRequest?.requestId, auth)
        if (response.success) {
            setRequest(response.content)
        }
        setVisibleLoading(false)

    }

    const signDocument = async () => {
        setVisibleLoading(true)
        let signatureBody = {
            subjectName: selectedSertificate?.name,
            thumbprint: selectedSertificate?.thumbprint,
            from: selectedSertificate?.validFrom,
            to: selectedSertificate?.validTo
        }
        var result = await addPdfStampService(currentRequest.requestId, signatureBody, auth)
        if (result.success) {
            let options = {
                encoding: 'base64'
            }
            await createHash(result.content.data, options)
            .then(async (resultHash) => {
                await createDetachedSignature(selectedSertificate.thumbprint, resultHash)
                .then(async (resultSign) => {
                    await addSignatureHadler(result.content.documentId, resultSign)
                })
                .catch(async (error) => {
                    handlePopapNotification("Не удалось создать подпись. Попробуйте еще раз. Подробнее: " + error.message)
                    await cancelSignHandler()
                    console.log(error.message)
                })
            })
            .catch (async (errorHash) => {
                handlePopapNotification("Не удалось создать ХЭШ-сумму файла. Попробуйте еще раз. Подробнее: " + errorHash.message)
                await cancelSignHandler()
                console.log(errorHash)
            })
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={5000}
                onHiding={() => setPopupNotification(false)}
            />
            <Popup
                dragEnabled={false}
                title="Подписание заявки"
                onHiding={onClose}
                visible={visible}
                enableBodyScroll={false}
                width={'600'}
                height={'auto'}
                maxHeight={'90%'}>
                <div>
                    {
                        isPluginReady && sertificates?.length !== 0 ? (
                            <div>
                                <h2>Выберите сертификат для подписи</h2>
                                <List
                                    dataSource={sertificates}
                                    itemRender={itemRender}
                                    selectByClick={true}
                                    selectionMode={'single'}
                                    onSelectionChanged={onSelectionChanged}
                                    noDataText="Сертификаты не загружены"
                                >
                                </List>
                                <Button onClick={signDocument} text='Подписать' style={{marginBottom: '10px', marginTop: '20px'}} visible={selectedSertificate !== null}/>
                            </div>
                        ) : (
                            <div>{plaginStatus}</div>
                        )
                    }
                </div>
            </Popup>
        </div>
    )
}

export default SignDocumentPopup