const getCompletedNews = async (page) => {
  try {
    const apiUrl = process.env.REACT_APP_BASE_NEWS_COMPLETED_GET + page;
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    let data = null

    if (response.status === 200) {
      data = await response.json();
    }
    else if (response.status === 400) {
      data = {
        success: false,
        message: `Не удалось загрузить новости`,
        content: null
      }
    }
    else {
      data = {
        success: false,
        message: "Ошибка сервера при получении новостей",
        content: null
      }
    }

    return data

  } catch (error) {
    let data = {
      success: false,
      message: "Не удается подключиться к серверу",
      content: null
    }
    return data
  }
};

export default getCompletedNews;