import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainPage from './pages/main/MainPage';
import "devextreme/dist/css/dx.generic.custom-scheme.css"
import "../src/css/basic.css"
import NotFoundPage from './pages/NotFound/NotFoundPage';
import NewsPage from './pages/news/NewsPage';
import InfoRedactor from './pages/admin/info/InfoRedactor';
import PagesPage from './pages/admin/pages/PagesPage';
import RolesList from './pages/admin/roles/RolesList';
import NewsReadtor from './pages/admin/news/NewsReadtor';
import PersonalDataAdmin from './pages/admin/confedential/PersonalDataAdmin';
import PrivacyPolicy from './pages/PrivarePolici/PrivacyPolicy';
import AccoutPageProfile from './pages/account/AccoutPageProfile';
import AccoutPageRequestDogovor from './pages/account/AccoutPageRequestDogovor';
import AccountAllRequests from './pages/account/AccountAllRequests';
import AccoutPageRequestTechnical from './pages/account/AccoutPageRequestTechnical';
import ManagerPage from './pages/manager/ManagerPage';
import ReqestFiles from './pages/admin/files/ReqestFiles';
import ManagerPrivateRoute from './base-components/Routes/ManagerPrivateRoute';
import AccountPrivateRoute from './base-components/Routes/AccountPrivateRoute';
import AdminPrivateRoute from './base-components/Routes/AdminPrivateRoute';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages } from 'devextreme/localization';

function App() {

  loadMessages(ruMessages);
  locale('ru-RU');
  return (
    <div >
      <Router>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/news' element={<NewsPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          
          <Route path='/admin/info' element={<AdminPrivateRoute element={<InfoRedactor />} />} />
          <Route path='/admin/pages' element={<AdminPrivateRoute element={<PagesPage />} />} />
          <Route path='/admin/roles' element={<AdminPrivateRoute element={<RolesList />} />} />
          <Route path='/admin/news' element={<AdminPrivateRoute element={<NewsReadtor />} />} />
          <Route path='/admin/files' element={<AdminPrivateRoute element={<ReqestFiles />} />} />
          <Route path='/admin/privacy-policy' element={<AdminPrivateRoute element={<PersonalDataAdmin />} />} />

          <Route path='/manager/requests' element={<ManagerPrivateRoute element={<ManagerPage/>} />} />

          <Route path='/account/profile' element={<AccountPrivateRoute element={<AccoutPageProfile />}/>} />
          <Route path='/account/new-request-dogovor' element={<AccountPrivateRoute element={<AccoutPageRequestDogovor />}/>} />
          <Route path='/account/new-request-technical' element={<AccountPrivateRoute element={<AccoutPageRequestTechnical />}/>} />
          <Route path='/account/requests' element={<AccountPrivateRoute element={<AccountAllRequests />}/>} />

          <Route path='*' element={<Navigate to={"/"}  />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
