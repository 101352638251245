const regAccountByAdmin = async (body, role, auth) => {
    try {
        let apiUrl = ""
        if (role === 1){
            apiUrl = process.env.REACT_APP_USER_REGISTER;
        }
        else if (role === 2){
            apiUrl = process.env.REACT_APP_USER_REG_ADMIN;
        }
        else if (role === 3){
            apiUrl = process.env.REACT_APP_USER_REG_MANAGER;
        }
        else{
            return {
                success: false,
                message: "Ошибка создания аккаунта, неверно указана роль",
                content: null
            }
        }
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token ?? "",
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        let data = null

        if (response.status === 200) {
            data = await response.json();
        }
        else if (response.status === 400) {
            data = {
                success: false,
                message: "Не удалось обновить аккаунт",
                content: null
            }
        }
        else {
            data = {
                success: false,
                message: "Ошибка обновления аккаунта на сервере",
                content: null
            }
        }

        return data

    } catch (error) {
        let data = {
            success: false,
            message: "Не удается подключиться к серверу",
            content: null
        }
        return data
    }
}

export default regAccountByAdmin