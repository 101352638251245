import { Button, TextBox } from "devextreme-react"
import DocumentButtonItem from "./DocumentButtonItem"
import { useState, useEffect, useRef } from "react"
import HtmlEditor, {
    Toolbar,
    MediaResizing,
    ImageUpload,
    Item,
    TableContextMenu,
    TableResizing
} from 'devextreme-react/html-editor';
import UploadFilePopup from "./UploadFilePopup"
import displayDocuments from "../services/displayDocuments";
import deleteDocument from "../services/deleteDocument";
import downloadFile from "../services/downloadFile";
import { useAuth } from "../../../../hooks/useAuth";


const SectionItem = ({ section, updateSection, deleteSection, setLoading, notification }) => {

    const [sectionName, setSectionName] = useState(null)
    const [sectionBody, setSectionBody] = useState(null)
    const [visbleUploadFile, setVisbleUploadFile] = useState(false)
    const [documentList, setDocumentList] = useState([])
    const htmlRedactorRef = useRef()
    const textBoxRef = useRef()

    const auth = useAuth()

    useEffect(() => {
        setSectionName(section?.name)
        if (htmlRedactorRef.current.instance) {
            htmlRedactorRef.current.instance.option('value', section.body)
        }
        setDocumentList([])
        displayDocumentsList()
    }, [section])

    const updateSectionHandler = () => {
        let body = section
        body.name = sectionName
        if (htmlRedactorRef.current.instance) {
            body.body = htmlRedactorRef.current.instance.option('value')
        }
        updateSection(body)
    }

    const clearNameSectionHandler = () => {
        let body = section
        body.name = null
        if (htmlRedactorRef.current.instance) {
            body.body = htmlRedactorRef.current.instance.option('value')
        }
        updateSection(body)
        if (textBoxRef.current.instance) {
            textBoxRef.current.instance.option('value', null)
            setSectionName(null)
        }
    }

    const onTextChanged = (e) => {
        setSectionName(e.value)
    }

    const deleteSectionHandler = () => {
        deleteSection(section.sectionId)
    }

    const displayDocumentsList = async () => {
        setLoading(true)
        var result = await displayDocuments(section?.sectionId, auth)
        if (result.success) {
            setDocumentList(result.content)
        } else {
            notification(result.message)
        }
        setLoading(false)
    }

    const deleteCurrentDocument = async (id) => {
        setLoading(true)
        var result = await deleteDocument(id, auth)
        if (result.success) {
            await displayDocumentsList()
        } else {
            notification(result.message)
        }
        setLoading(false)
    }

    const downloadFileHandler = async (element) => {
        setLoading(true)
        var result = await downloadFile(element.documentId)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', element.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            notification(result.message)
        }
        setLoading(false)
    }

    const fontValues = [
        'Arial',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana',
    ];

    const headerValues = [false, 1, 2, 3, 4, 5];
    const fontSizeOptions = {
        inputAttr: {
            'aria-label': 'Font size',
        },
    };
    const fontFamilyOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const headerOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];

    return (
        <div>
            <UploadFilePopup visible={visbleUploadFile} section={section} onHide={() => setVisbleUploadFile(false)} updateList={displayDocumentsList} />
            <div style={{ marginBottom: '10px' }}>
                <TextBox ref={textBoxRef} onValueChanged={onTextChanged} placeholder={section?.name ?? "Без названия"} />
                <Button style={{marginTop: '5px'}} text="Очистить название" onClick={clearNameSectionHandler}/>
            </div>
            <div>
                <div style={{ marginBottom: '10px' }}>
                    <HtmlEditor ref={htmlRedactorRef} valueType="html">
                        <MediaResizing enabled={true} />
                        <ImageUpload tabs={['file', 'url']} fileUploadMode="base64" />
                        <TableContextMenu enabled={true} />
                        <TableResizing enabled={true} />
                        <Toolbar multiline={true}>
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
                            <Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="strike" />
                            <Item name="underline" />
                            <Item name="separator" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                            <Item name="alignJustify" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="separator" />
                            <Item name="header" acceptedValues={headerValues} options={headerOptions} />
                            <Item name="separator" />
                            <Item name="color" />
                            <Item name="background" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item name="image" />
                            <Item name="separator" />
                            <Item name="clear" />
                            <Item name="codeBlock" />
                            <Item name="blockquote" />
                            <Item name="separator" />
                            <Item name="insertTable" />
                            <Item name="deleteTable" />
                            <Item name="insertRowAbove" />
                            <Item name="insertRowBelow" />
                            <Item name="deleteRow" />
                            <Item name="insertColumnLeft" />
                            <Item name="insertColumnRight" />
                            <Item name="deleteColumn" />
                        </Toolbar>

                    </HtmlEditor>
                </div>
            </div>
            {
                documentList !== null && documentList.length !== 0 && (
                    <div>
                        <div style={{ marginLeft: '10px', marginBottom: '5px' }}>Прикрепленные документы</div>
                        {
                            documentList.map(c => (
                                <DocumentButtonItem downloadFile={downloadFileHandler} document={c} deleteDocument={deleteCurrentDocument} />
                            ))
                        }
                    </div>
                )
            }

            <div style={{ marginBottom: '10px' }}>
                <Button onClick={() => setVisbleUploadFile(true)} text="Добавить документ" />
            </div>
            <div className="pages-button-bottom">
                <Button onClick={updateSectionHandler} text="Сохранить секцию" />
                <Button onClick={deleteSectionHandler} text="Удалить секцию" />
            </div>

        </div>
    )
}

export default SectionItem