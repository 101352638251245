const displaySections = async (id, auth) => {
    try {
        const apiUrl = process.env.REACT_APP_SECTION_DISPLAY+id;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + auth.token ?? ""
          }
        });
        
        let data = null
  
        if (response.status === 200){
          data = await response.json();
        }
        else if (response.status === 400) {
          data = {
            success: false,
            message: `Не удалось загрузить секции`,
            content: null
          }
        } 
        else {
          data = {
            success: false,
            message: "Ошибка сервера при получении секций",
            content: null
          }
        }
  
        return data
  
      } catch (error) {
        let data = {
          success: false,
          message: "Не удается подключиться к серверу",
          content: null
        }
       return data
      }
}
export default displaySections