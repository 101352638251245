import { Button, LoadPanel, Toast } from "devextreme-react"
import MainHeader from "../../base-components/MainHeader"
import NewsUnit from "./components/NewsUnit"
import "./css/news-css.css"
import { useState, useEffect } from "react"
import getCompletedNews from "./services/getCompletedNews"

const NewsPage = () => {

    const newsInit = {
        totalCount: 0,
        news: []
    }
    const [currentPage, setCurrentPage] = useState(1)
    const [newsList, setNewsList] = useState(newsInit)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const loadMorePages = async() => {
        setVisibleLoading(true)
        var news = await getCompletedNews(currentPage + 1)
        if (news.success) {
            let oldValue = newsList
            if (news.content !== null && news?.news !== null){
                oldValue.news = oldValue.news.concat(news.content.news)
                setCurrentPage(currentPage + 1)
                setNewsList(oldValue)
            }
            
        } 
        setVisibleLoading(false)
    }

    const updateNewsList = async () => {
        setVisibleLoading(true)
        var news = await getCompletedNews(1)
        if (news.success) {
            setNewsList(news.content)
            setCurrentPage(1)
        } 

        setVisibleLoading(false)
    }

    useEffect(() => {
        document.title = "Новости"
        updateNewsList()
    }, [])


    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <MainHeader />
            <div className="news-body-container">
                <div className="caption">
                    Новости
                </div>
                <div>
                    {
                        newsList.news.length === 0 ? (
                            <div>Здесь пока нет новостей</div>
                        ) : (
                            newsList.news.map(c => (
                                <NewsUnit news={c} />
                            ))
                        )
                    }
                </div>
                {
                    newsList.news.length !== 0 && newsList.news.length < newsList.totalCount && (
                        <Button onClick={loadMorePages} className="load-news-button" text="Загрузить еще" />
                    )
                }

            </div>



        </div>
    )
}

export default NewsPage