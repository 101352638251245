import AdminHeader from "../../../base-components/AdminHeader"
import MainHeader from "../../../base-components/MainHeader"
import { useRef, useState, useEffect } from "react"
import { LoadPanel, Toast } from 'devextreme-react';
import HtmlEditor, {
    Toolbar,
    MediaResizing,
    ImageUpload,
    Item,
    TableContextMenu,
    TableResizing,
} from 'devextreme-react/html-editor';
import { Button, TextBox } from "devextreme-react";
import getPersonalData from "./services/getPersonalData";
import updatePersonalData from "./services/updatePersonalData";
import { useAuth } from "../../../hooks/useAuth";

const PersonalDataAdmin = () => {

    const [current, setCurrent] = useState(null)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupSuccessNotification, setPopupSuccessNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)
    const htmlEditorRef = useRef()
    const auth = useAuth()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const fontValues = [
        'Arial',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana',
    ];

    const headerValues = [false, 1, 2, 3, 4, 5];
    const fontSizeOptions = {
        inputAttr: {
            'aria-label': 'Font size',
        },
    };
    const fontFamilyOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const headerOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];

    const displayData = async () => {
        setVisibleLoading(true)
        var response = await getPersonalData()
        if (response.success) {
            if (response.content !== null){
                if (htmlEditorRef.current.instance) {
                    htmlEditorRef.current.instance.option('value', response.content.body)
                }
                setCurrent(response.content)
            }
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    useEffect(() => {
        document.title = "Редактор политики конфиденциальности";
        displayData()
    }, [])

    const updatePrivatePolicy = async () => {
        setVisibleLoading(true)
        let body = current
        body.body = htmlEditorRef.current.instance.option('value')
        var response = await updatePersonalData(current, auth)
        if (response.success) {
            setPopupSuccessNotification(true)
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    return (
        <div>
            <MainHeader />
            <AdminHeader />
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <Toast
                visible={popupSuccessNotification}
                message={"Запись сохранена"}
                type={'success'}
                displayTime={3000}
                onHiding={() => setPopupSuccessNotification(false)}
            />
            <div style={{ padding: '50px' }}>
                <h1>Политика конфиденциальности</h1>
                <div style={{ marginBottom: '10px' }}>
                    <HtmlEditor ref={htmlEditorRef} valueType="html">
                        <MediaResizing enabled={true} />
                        <ImageUpload tabs={['file', 'url']} fileUploadMode="base64" />
                        <TableContextMenu enabled={true} />
                        <TableResizing enabled={true} />
                        <Toolbar multiline={true}>
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
                            <Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="strike" />
                            <Item name="underline" />
                            <Item name="separator" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                            <Item name="alignJustify" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="separator" />
                            <Item name="header" acceptedValues={headerValues} options={headerOptions} />
                            <Item name="separator" />
                            <Item name="color" />
                            <Item name="background" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item name="image" />
                            <Item name="separator" />
                            <Item name="clear" />
                            <Item name="codeBlock" />
                            <Item name="blockquote" />
                            <Item name="separator" />
                            <Item name="insertTable" />
                            <Item name="deleteTable" />
                            <Item name="insertRowAbove" />
                            <Item name="insertRowBelow" />
                            <Item name="deleteRow" />
                            <Item name="insertColumnLeft" />
                            <Item name="insertColumnRight" />
                            <Item name="deleteColumn" />
                        </Toolbar>

                    </HtmlEditor>
                </div>
                <div >
                    <Button onClick={updatePrivatePolicy} text="Сохранить" />
                </div>
            </div>
        </div>
    )
}
export default PersonalDataAdmin