import MainHeader from '../../base-components/MainHeader';
import { useNavigate } from "react-router-dom"
import { LoadPanel as Loading, Toast, Button } from 'devextreme-react';
import { useEffect, useState } from "react";
import DataGrid, { Column, FilterRow, Selection, LoadPanel, Paging, Pager } from 'devextreme-react/data-grid';
import { Form, SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import Cookies from 'js-cookie';
import { useAuth } from '../../hooks/useAuth';
import "./css/manager-style.css"
import ShowRequestPopup from './components/ShowRequestPopup';
import getDisplayRequestForManager from './services/getDisplayRequestForManager';
import CustomStore from "devextreme/data/custom_store";
import deleteRequestService from '../account/services/deleteRequestService';

const ManagerPage = () => {

    const initFilters = {
        requestNumber: null,
        status: null,
        kind: null,
        userType: null,
        dateFrom: null,
        dateTo: null
    }

    const statusList = [
        {
            name: "Сформирована",
            type: 2
        },
        {
            name: "Подписана",
            type: 3
        },
        {
            name: "Отправлена",
            type: 4
        },
        {
            name: "Одобрена",
            type: 5
        },
        {
            name: "Отклонена",
            type: 5
        },
    ]

    const kindList = [
        {
            name: "Технические условия",
            type: 1
        },
        {
            name: "Заключение договора",
            type: 2
        }
    ]

    const userTypeList = [
        {
            name: "Физ. лицо",
            type: 1
        },
        {
            name: "ИП",
            type: 2
        },
        {
            name: "Юр. лицо",
            type: 3
        }
    ]

    const initRequestList = new CustomStore({
        key: 'requestId',
        async load(loadOptions) {
            return {
                data: [],
                totalCount: 0
            }
        }
    })

    const [filters, setFilters] = useState(initFilters)
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)
    const [requests, setRequests] = useState(initRequestList)
    const [visibleShow, setVisibleShow] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState(null)

    const auth = useAuth()
    const navigation = useNavigate()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const logOut = () => {
        Cookies.remove('token');
        const broadcastChannel = new BroadcastChannel('action_channel');
        broadcastChannel.postMessage({ type: 'ACTION_PERFORMED' });
        broadcastChannel.close();
    }

    useEffect(() => {
        document.title = "Список заявок"
    }, [])

    useEffect(() => {
        const broadcastChannel = new BroadcastChannel('action_channel');

        function handleMessage(event) {
            if (event.data.type === 'ACTION_PERFORMED') {
                navigation('/')
            }
        }

        broadcastChannel.addEventListener('message', handleMessage);

        return () => {
            broadcastChannel.removeEventListener('message', handleMessage);
            broadcastChannel.close();
        };
    }, []);

    useEffect(() => {
        getRequestsListHandler()
    }, [pageIndex, pageSize])

    const getRequestsListHandler = async () => {
        setVisibleLoading(true)
        setSelectedRequest(null)
        let body = {
            pageIndex: pageIndex,
            pageSize: pageSize,
            filters: filters
        }
        var result = await getDisplayRequestForManager(body, auth);
        if (result.success) {
            let store = new CustomStore({
                key: 'requestId',
                async load(loadOptions) {
                    return {
                        data: result.content.data,
                        totalCount: result.content.totalCount
                    }
                }
            })
            setRequests(store)
        } else {
            setRequests(initRequestList)
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const updateAgainHandler = () => {
        setPageIndex(0)
        getRequestsListHandler()
    }

    const deleteRequestHandler = async (id) => {
        setVisibleLoading(true)
        var result = await deleteRequestService(id, auth)
        if (result.success) {
            updateAgainHandler()
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }


    const renderUserType = ({ data }) => {
        let display = null
        if (data?.userType == 1) {
            display = "Физ. лицо"
        }
        else if (data?.userType == 2) {
            display = "ИП"
        }
        else if (data?.userType == 3) {
            display = "Юр. лицо"
        }

        return (
            <span>{display}</span>
        )
    }

    const renderKind = ({ data }) => {
        let display = null
        if (data?.kind == 1) {
            display = "Технические условия"
        }
        else if (data?.kind == 2) {
            display = "Заключение договора"
        }

        return (
            <span>{display}</span>
        )
    }

    const changeRowColor = (e) => {
        if (e.rowType === 'data') {
            if (e.data.status !== null && e.data.status !== 0) {
                if (e.data.status === 2) {
                    e.rowElement.style.backgroundColor = "SkyBlue"
                }
                else if (e.data.status === 3) {
                    e.rowElement.style.backgroundColor = "SkyBlue"
                }
                else if (e.data.status === 4) {
                    e.rowElement.style.backgroundColor = "LightSalmon"
                }
                else if (e.data.status === 5) {
                    e.rowElement.style.backgroundColor = "MediumSeaGreen"
                }
                else if (e.data.status === 6) {
                    e.rowElement.style.backgroundColor = "IndianRed"
                }
            }
        }
    }

    const renderStatus = ({ data }) => {
        let display = null
        if (data?.status == 2) {
            display = "Заявка сформирована"
        }
        else if (data?.status == 3) {
            display = "Заявка подписана"
        }
        else if (data?.status == 4) {
            display = "Заявка отправлена"
        }
        else if (data?.status == 5) {
            display = "Заявка одобрена"
        }
        else if (data?.status == 6) {
            display = "Заявка отклонена"
        }
        else {
            display = "Ошибка создания"
        }

        return (
            <span>{display}</span>
        )
    }

    const renderDate = ({ data }) => {
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }

        let date = new Date(data.createAt)

        return (
            <span>{date.toLocaleDateString('ru-RU', options)}</span>
        )
    }

    const onSelectionChanged = (e) => {
        setSelectedRequest(e.selectedRowsData[0])
    }

    const showCurrentAccount = (e) => {
        setSelectedRequest(e.data)
        setVisibleShow(true)
    }


    return (
        <div>
            <MainHeader />
            <ShowRequestPopup auth={auth} updateList={updateAgainHandler} deleteRequest={deleteRequestHandler} requestData={selectedRequest} visible={visibleShow} onHide={() => setVisibleShow(false)} />
            <div className='manager-logout-button'>
                <Button onClick={logOut} text={`${auth.username} Выйти`} />
            </div>
            <Loading
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <div className="manager-body-container">
                <div>
                    <Form formData={filters} colCount={2}>
                        <GroupItem >
                            <SimpleItem
                                dataField="requestNumber"
                                editorType="dxTextBox"
                            >
                                <Label text={"Номер заявка"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="status"
                                editorType="dxSelectBox"
                                editorOptions={{ placeholder: 'Выберите статус', dataSource: statusList, valueExpr: "type", displayExpr: "name", showClearButton: true }}
                            >
                                <Label text={"Статус заявки"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="kind"
                                editorType="dxSelectBox"
                                editorOptions={{ placeholder: 'Выберите вид', dataSource: kindList, valueExpr: "type", displayExpr: "name", showClearButton: true }}
                            >
                                <Label text={"Вид заявки"} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem
                                dataField="userType"
                                editorType="dxSelectBox"
                                editorOptions={{ placeholder: 'Выберите вид пользователя', dataSource: userTypeList, valueExpr: "type", displayExpr: "name", showClearButton: true }}
                            >
                                <Label text={"Вид пользователя"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="dateFrom"
                                editorType="dxDateBox"
                                editorOptions={{ displayFormat: "dd.MM.yyyy", useMaskBehavior: true, showClearButton: true }}
                            >
                                <Label text={"Дата создания с"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="dateTo"
                                editorType="dxDateBox"
                                editorOptions={{ displayFormat: "dd.MM.yyyy", useMaskBehavior: true, showClearButton: true }}
                            >
                                <Label text={"Дата создания по"} />
                            </SimpleItem>
                        </GroupItem>

                    </Form>
                </div>
                <div className="manager-main-buttons-container">
                    <div className="manager-buttons-container">
                        <Button visible={selectedRequest} onClick={() => setVisibleShow(true)} text="Посмотреть заявку" />
                        <Button visible={selectedRequest} onClick={async () => deleteRequestHandler(selectedRequest?.requestId)} text="Удалить заявку" />
                    </div>
                    <Button onClick={updateAgainHandler} text="Обновить список" />
                </div>
                <div>
                    <DataGrid
                        onSelectionChanged={onSelectionChanged}
                        onRowPrepared={changeRowColor}
                        onRowDblClick={showCurrentAccount}
                        dataSource={requests}
                        showBorders={true}
                        hoverStateEnabled={true}
                        keyExpr="requestId"
                        noDataText='Заявки отсутствуют'
                        rowAlternationEnabled={false}
                        showRowLines={true}
                        remoteOperations={true}
                    >
                        <Paging enabled={true} />
                        <LoadPanel enabled={false} />
                        <Selection mode="single" />
                        <Column alignment="center" dataField="requestNumber" caption="№" />
                        <Column alignment="center" dataField="kind" caption="Вид" cellRender={renderKind} />
                        <Column alignment="center" dataField="userType" caption="Тип пользователя" cellRender={renderUserType} />
                        <Column alignment="center" dataField="status" caption="Статус" cellRender={renderStatus} />
                        <Column alignment="center" dataField="createAt" caption="Дата создания" cellRender={renderDate} />

                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 30, 50]}
                            showNavigationButtons={true}
                            visible={true}
                        />
                        <Paging
                            defaultPageSize={10}
                            onPageSizeChange={setPageSize}
                            onPageIndexChange={setPageIndex}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                        />
                    </DataGrid>
                </div>
            </div>

        </div>
    )
}
export default ManagerPage