import { Button, CheckBox, Popup, RadioGroup } from "devextreme-react"
import { Form, SimpleItem, GroupItem, Label, ButtonItem, RequiredRule, EmailRule } from 'devextreme-react/form';
import { useRef, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import "./css/auth-popup.css"
import CryptoJS from 'crypto-js';
import registrationService from "../base-services/registrationService";
import { LoadPanel, Toast } from 'devextreme-react';
import Cookies from 'js-cookie';
import authorizationService from "../base-services/authorizationService";
import { useAuth } from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";

const AuthPopap = ({ visible, onHide }) => {
    const authData = {
        username: "",
        password: ""
    }

    const registerData = {
        username: "",
        password: "",
        password2: "",
        inn: "",
        ogrnip: "",
        ogrn: ""
    }


    const authTypes = [
        {
            name: 'Физ. лицо',
            type: 1,
        },
        {
            name: 'ИП',
            type: 2,
        },
        {
            name: 'Юр. лицо',
            type: 3,
        }
    ]
    const [selectedAuthType, setSelectedAuthType] = useState(authTypes[0].type)

    const [isLogin, setIsLogin] = useState(true)
    const [isChecked, setIsChecked] = useState(false)
    const [isCheckedCookie, setIsCheckedCookie] = useState(false)
    const [isCheckedTruth, setIsCheckedTruth] = useState(false)
    const [currentAuthData, setCurrentAuthData] = useState(authData)
    const [currentRegData, setCurrentRegData] = useState(registerData)
    const [errorData, setErrorData] = useState([])

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const [modeAuthButton, setModeAuthButton] = useState('password')
    const [modeRegButton, setModeRegButton] = useState('password')

    const popupRef = useRef()
    const navigate = useNavigate()
    const auth = useAuth()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const showAuthButton = () => {
        if (modeAuthButton === 'password'){
            setModeAuthButton('text')
        }else{
            setModeAuthButton('password')
        }
    }

    const showRegButton = () => {
        if (modeRegButton === 'password'){
            setModeRegButton('text')
        }else{
            setModeRegButton('password')
        }
    }

    const HidePopup = () => {
        setIsLogin(true)
        if (popupRef.current) {
            popupRef.current.instance.option('title', 'Авторизация');
        }
        setSelectedAuthType(1)
        setCurrentAuthData(authData)
        setCurrentRegData(registerData)
        setIsChecked(false)
        setIsCheckedCookie(false)
        setErrorData([])
        setIsCheckedTruth(false)
        setModeAuthButton('password')
        setModeRegButton('password')
        onHide()
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const checkPasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength += 1; // Минимальная длина 8 символов
        if (/[A-Z]/.test(password)) strength += 1; // Наличие заглавных букв
        if (/[a-z]/.test(password)) strength += 1; // Наличие строчных букв
        if (/\d/.test(password)) strength += 1; // Наличие цифр
        if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) strength += 1; // Наличие специальных символов
        return strength;
    };

    const getRoleByToken = (token) => {
        try {
            if (token) {
                let decoded = jwtDecode(token)
                let startRole = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                if (startRole) {
                    if (startRole === 'b512d97e7cbf97c273e4db073bbb547aa65a84589227f8f3d9e4a72b9372a24d') {
                        return 1
                    }
                    else if (startRole === 'c1c224b03cd9bc7b6a86d77f5dace40191766c485cd55dc48caf9ac873335d6f') {
                        return 2
                    }
                    else if (startRole === '8b2085f74dfa9c78a23b7d573c23d27d6d0b0e50c82a9b13138b193325be3814') {
                        return 3
                    }
                }
            }
        }
        catch {

        }
        return 0
    }

    const validateAuthForm = async () => {
        let errors = []
        if (isEmptyOrSpaces(currentAuthData?.username)) {
            errors.push("E-mail не может быть пустым")
        }
        else {
            let validMail = validateEmail(currentAuthData?.username)
            if (!validMail) {
                errors.push("Неверно указан E-mail")
            }
        }

        if (isEmptyOrSpaces(currentAuthData?.password)) {
            errors.push("Пароль не может быть пустым")
        }
        return errors
    }

    const validateRegForm = async () => {

        let errors = []

        if (selectedAuthType === 1) {
            if (isEmptyOrSpaces(currentRegData?.inn)) {
                errors.push("Не указан ИНН")
            }
        }
        else if (selectedAuthType === 2) {
            if (isEmptyOrSpaces(currentRegData?.ogrnip)) {
                errors.push("Не указан ОГРНИП")
            }
        }
        else if (selectedAuthType === 3) {
            if (isEmptyOrSpaces(currentRegData?.ogrn)) {
                errors.push("Не указан ОГРН")
            }
        }
        else {
            errors.push("Не указан тип учетной записи")
        }
        if (isEmptyOrSpaces(currentRegData?.username)) {
            errors.push("E-mail не может быть пустым")
        } else {
            let validMail = validateEmail(currentRegData?.username)
            if (!validMail) {
                errors.push("Неверно указан E-mail")
            }
        }
        if (isEmptyOrSpaces(currentRegData?.password)) {
            errors.push("Пароль не может быть пустым")
        } else {
            let stPassword = checkPasswordStrength(currentRegData?.password)
            if (stPassword < 5) {
                errors.push("Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы")
            }
        }
        if (currentRegData?.password !== currentRegData?.password2) {
            errors.push("Пароли не совпадают")
        }

        return errors
    }

    const isEmptyOrSpaces = (str) => {
        return str === null || str.match(/^ *$/) !== null;
    }

    const onCheckedPrivate = (e) => {
        setIsChecked(e.value)
    }

    const onCheckedCookie = (e) => {
        setIsCheckedCookie(e.value)
    }

    const onCheckedTruth = (e) => {
        setIsCheckedTruth(e.value)
    }

    const signInCore = async () => {
        let errors = await validateAuthForm()
        setErrorData(errors)
        if (errors !== null && errors?.length !== 0) {
            return
        }
        const hash = CryptoJS.SHA256(currentAuthData.password).toString();
        let body = {
            username: currentAuthData.username,
            password: hash
        }
        setVisibleLoading(true)
        var response = await authorizationService(body)
        if (response.success) {
            let role = getRoleByToken(response?.content?.token)
            if (role === 1) {
                await setToken(response.content)
                setTimeout(() => navigate('/account/profile'), 200) 
                await HidePopup()
            }
            else if (role === 2) {
                await setToken(response.content)
                setTimeout(() => navigate('/admin/info'), 200) 
                await HidePopup()
            }
            else if (role === 3) {
                await setToken(response.content)
                setTimeout(() => navigate('/manager/requests'), 200) 
                await HidePopup()
            }
            else {
                handlePopapNotification("Ошибка авторизации")
            }
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    const regUserCore = async () => {
        let errors = await validateRegForm()
        setErrorData(errors)
        if (errors !== null && errors?.length !== 0) {
            return
        }
        const hash = CryptoJS.SHA256(currentRegData.password).toString();
        let body = {
            username: currentRegData.username,
            password: hash,
            ogrn: currentRegData.ogrn,
            orgnip: currentRegData.ogrnip,
            inn: currentRegData.inn,
            kind: selectedAuthType
        }

        setVisibleLoading(true)
        var response = await registrationService(body)
        if (response.success) {
            let role = getRoleByToken(response?.content?.token)
            if (role === 1) {
                await setToken(response.content)
                setTimeout(() => navigate('/account/profile'), 500) 
                await HidePopup()
            }
            else if (role === 2) {
                await setToken(response.content)
                setTimeout(() => navigate('/admin/info'), 500) 
                await HidePopup()
            }
            else if (role === 3) {
                await setToken(response.content)
                setTimeout(() => navigate('/manager/requests'), 500) 
                await HidePopup()
            }
            else {
                handlePopapNotification("Ошибка регистрации")
            }
        }
        else {
            handlePopapNotification(response.message)
        }
        setTimeout(() => setVisibleLoading(false), 500) 
        
    }

    const onRadioButtonChanged = (e) => {
        setSelectedAuthType(e.value)
        let body = currentRegData
        if (e.value === 1) {
            body.ogrn = ""
            body.ogrnip = ""
            setCurrentRegData(body)
        }
        else if (e.value === 2) {
            body.ogrn = ""
            body.inn = ""
            setCurrentRegData(body)
        }
        else if (e.value === 3) {
            body.ogrnip = ""
            body.inn = ""
            setCurrentRegData(body)
        }
    }

    const changeIsLogin = () => {
        if (isLogin === true) {
            setIsLogin(false)
            if (popupRef.current) {
                popupRef.current.instance.option('title', 'Регистрация');
            }
        } else {
            setIsLogin(true)
            if (popupRef.current) {
                popupRef.current.instance.option('title', 'Авторизация');
            }
        }

        setSelectedAuthType(1)
        setCurrentAuthData(authData)
        setCurrentRegData(registerData)
        setIsChecked(false)
        setIsCheckedCookie(false)
        setErrorData([])
        setIsCheckedTruth(false)
        setModeAuthButton('password')
        setModeRegButton('password')
    }

    const setToken = (data) => {
        Cookies.set('token', JSON.stringify(data), { expires: 1 });
    }

    const deleteToken = () => {
        Cookies.remove('token');
    }

    const getToken = () => {
        return Cookies.get('token');
    }

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <Popup
                ref={popupRef}
                dragEnabled={false}
                visible={visible}
                onHiding={HidePopup}
                title={"Авторизация"}
                width={'600'}
                height={'auto'}
                enableBodyScroll={false}
            >
                {
                    isLogin ? (
                        <div>
                            <Form formData={currentAuthData}>
                                <SimpleItem
                                    dataField="username"
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'E-mail' }}>

                                    <Label text={"E-mail"} />
                                    <EmailRule message="Неверно введен E-mail" />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="password"
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'Пароль', mode: modeAuthButton, buttons: [{name: "password", location: 'after', options: {icon: 'eyeopen', stylingMode: 'text', onClick: showAuthButton}}]}}>

                                    <Label text={"Пароль"} />
                                </SimpleItem>
                            </Form>
                            {
                                errorData?.length !== 0 &&
                                <div style={{ marginTop: '10px', justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                                    {
                                        errorData.map(c => (
                                            <div style={{ color: 'red' }}>{c}</div>
                                        ))
                                    }
                                </div>
                            }

                            <div className="login-button-auth">
                                <Button onClick={signInCore} className="login-item-auth" text="Войти" />
                                <Link onClick={changeIsLogin} className="login-item-auth">Регистрация</Link>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div style={{ marginBottom: '10px' }}>
                                <p>Регистрация как</p>
                                <RadioGroup
                                    id='type'
                                    valueExpr="type"
                                    items={authTypes}
                                    displayExpr='name'
                                    layout="horizontal"
                                    onValueChanged={onRadioButtonChanged}
                                    value={selectedAuthType}
                                />
                            </div>

                            <Form formData={currentRegData}>
                                <SimpleItem
                                    dataField="inn"
                                    visible={selectedAuthType == 1}
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'ИНН' }}>

                                    <Label text={"ИНН"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="ogrnip"
                                    visible={selectedAuthType == 2}
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'ОГРНИП' }}>

                                    <Label text={"ОГРНИП"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="ogrn"
                                    visible={selectedAuthType == 3}
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'ОГРН' }}>

                                    <Label text={"ОГРН"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="username"
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'E-mail' }}>

                                    <Label text={"E-mail"} />
                                    <EmailRule message="Неверно введен E-mail" />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="password"
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'Пароль', mode: modeRegButton, buttons: [{name: "password", location: 'after', options: {icon: 'eyeopen', stylingMode: 'text', onClick: showRegButton}}] }}>

                                    <Label text={"Пароль"} />
                                </SimpleItem>
                                <SimpleItem
                                    dataField="password2"
                                    editorType="dxTextBox"
                                    editorOptions={{ placeholder: 'Повторите пароль', mode: modeRegButton, buttons: [{name: "password", location: 'after', options: {icon: 'eyeopen', stylingMode: 'text', onClick: showRegButton}}] }}>

                                    <Label text={"Повторите пароль"} />
                                </SimpleItem>
                            </Form>
                            <div className="login-button-auth">
                                <div style={{ alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                    <CheckBox
                                        onValueChanged={onCheckedPrivate}
                                        value={isChecked}
                                    />
                                    <div style={{ marginLeft: '5px', marginRight: '5px' }}>Разделяю </div>
                                    <Link target="_blank" to='/privacy-policy'>политику обработки персональных данных</Link>
                                </div>

                                <div style={{ alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                    <CheckBox
                                        onValueChanged={onCheckedCookie}
                                        value={isCheckedCookie}
                                    />
                                    <div style={{ marginLeft: '5px', marginRight: '5px' }}>Разрешаю использование файлов cookie </div>
                                </div>
                                <div style={{ alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                    <CheckBox
                                        onValueChanged={onCheckedTruth}
                                        value={isCheckedTruth}
                                    />
                                    <div style={{ marginLeft: '5px', marginRight: '5px' }}>Гарантиую достоверность введенных мной данных </div>
                                </div>
                                {
                                    errorData?.length !== 0 &&
                                    <div style={{ marginTop: '10px', justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                                        {
                                            errorData.map(c => (
                                                <div style={{ color: 'red' }}>{c}</div>
                                            ))
                                        }
                                    </div>
                                }
                                <Button onClick={regUserCore} className="login-item-auth" text="Регистрация" disabled={!isChecked || !isCheckedCookie || !isCheckedTruth} />
                                <Link onClick={changeIsLogin} className="login-item-auth">Войти</Link>
                            </div>

                        </div>
                    )
                }

            </Popup>
        </div>
    )
}
export default AuthPopap