import { Popup, FileUploader, Button } from "devextreme-react"
import { useEffect, useRef, useState } from "react";
import { LoadPanel, Toast } from 'devextreme-react';
import "./css/request-files-style.css"
import MainHeader from "../../../base-components/MainHeader";
import AdminHeader from "../../../base-components/AdminHeader";
import { Link } from "react-router-dom";
import downloadTemplate from "./services/downloadTemplate";
import uploadTemplate from "./services/uploadTemplate";
import { useAuth } from "../../../hooks/useAuth";

const ReqestFiles = () => {

    const uploadRefDogovor = useRef()
    const uploadRefTechnical = useRef()
    const [selectedFileDogovor, setSelectedFileDogovor] = useState(null)
    const [selectedFileTechnical, setSelectedFileTechnical] = useState(null)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationSuccess, setPopupNotificationSuccess] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const auth = useAuth()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    useEffect(() => {
        document.title = "Файлы шаблонов";
    }, [])

    const handleFileDogovorChange = (e) => {
        setSelectedFileDogovor(e.value[0])
    };

    const handleFileTechnicalChange = (e) => {
        setSelectedFileTechnical(e.value[0])
    };

    const downloadDogovor = async () => {
        setVisibleLoading(true)
        var result = await downloadTemplate(1, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `dogovor_template.docx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadTechnical = async () => {
        setVisibleLoading(true)
        var result = await downloadTemplate(2, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `technical_template.docx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const uploadFileDogovor = async () => {
        setVisibleLoading(true)
        var result = await uploadTemplate(selectedFileDogovor, 1, auth)
        if (result.success) {
            if (uploadRefDogovor.current.instance) {
                uploadRefDogovor.current.instance.option('value', [])
            }
            setSelectedFileDogovor(null)
            setPopupNotificationSuccess(true)
        }
        else{
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const uploadFileDTechnical = async () => {
        setVisibleLoading(true)
        var result = await uploadTemplate(selectedFileTechnical, 2, auth)
        if (result.success) {
            if (uploadRefTechnical.current.instance) {
                uploadRefTechnical.current.instance.option('value', [])
            }
            setSelectedFileDogovor(null)
            setPopupNotificationSuccess(true)
        }
        else{
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    return (
        <div >
            <MainHeader />
            <AdminHeader />
            <div className="files-main-container">
                <Link onClick={downloadDogovor}>
                    <h3>Заявка на заключение договора о подключении к системе теплоснабжения</h3>
                </Link>
                <div className="bordered-file-div">
                    <FileUploader
                        allowCanceling={true}
                        ref={uploadRefDogovor}
                        multiple={false}
                        selectButtonText="Выберите файл"
                        uploadButtonText="Загрузить"
                        uploadMode="useForm"
                        onValueChanged={handleFileDogovorChange}
                        labelText="Или перетащите сюда"
                        readyToUploadMessage="Готово к загрузке"
                    />
                    {
                        selectedFileDogovor && <Button onClick={uploadFileDogovor} style={{ marginTop: '10px' }} text="Загрузить файл" />
                    }
                </div>
                <Link onClick={downloadTechnical}>
                    <h3>Заявка о предоставлении технических условий на технологическое присоединение</h3>
                </Link>
                <div className="bordered-file-div">
                    <FileUploader
                        ref={uploadRefTechnical}
                        multiple={false}
                        selectButtonText="Выберите файл"
                        uploadButtonText="Загрузить"
                        uploadMode="useForm"
                        onValueChanged={handleFileTechnicalChange}
                        labelText="Или перетащите сюда"
                        readyToUploadMessage="Готово к загрузке"
                    />
                    {
                        selectedFileTechnical && <Button onClick={uploadFileDTechnical} style={{ marginTop: '10px' }} text="Загрузить файл" />
                    }
                </div>
            </div>

            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <Toast
                visible={popupNotificationSuccess}
                message={"Успешная загрузка"}
                type={'success'}
                displayTime={2000}
                onHiding={() => setPopupNotificationSuccess(false)}
            />

        </div>
    )
}

export default ReqestFiles