import { useState, useEffect, useRef } from "react";
import { Button, TextBox } from "devextreme-react";
import HtmlEditor, {
    Toolbar,
    MediaResizing,
    ImageUpload,
    Item,
    TableContextMenu,
    TableResizing,
} from 'devextreme-react/html-editor';
import createOrUpdateNews from "../services/createOrUpdateNews";
import deleteCurrentNews from "../services/deleteCurrentNews";
import { useAuth } from "../../../../hooks/useAuth";

const CurrentNewsCreator = ({ updateList, setVisibleLoading, handlePopapNotification, current }) => {

    const [newsName, setNewsName] = useState(null)
    const [newsBody, setNewsBody] = useState(null)
    const textBoxRef = useRef()
    const htmlEditorRef = useRef()
    const auth = useAuth()

    const fontValues = [
        'Arial',
        'Courier New',
        'Georgia',
        'Impact',
        'Lucida Console',
        'Tahoma',
        'Times New Roman',
        'Verdana',
    ];

    const headerValues = [false, 1, 2, 3, 4, 5];
    const fontSizeOptions = {
        inputAttr: {
            'aria-label': 'Font size',
        },
    };
    const fontFamilyOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const headerOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];

    useEffect(() => {
        if (textBoxRef.current.instance) {
            textBoxRef.current.instance.option('value', null)
        }
        setNewsName(current.name)
        setNewsBody(current.newsBody)

        if (htmlEditorRef.current.instance) {
            htmlEditorRef.current.instance.option('value', current.newsBody)
        }
    }, [current])

    const updateCurrentNewsHandler = async () => {
        if (htmlEditorRef?.current?.instance) {
            setVisibleLoading(true)
            let body = current
            body.status = 0
            body.name = newsName
            body.newsBody = htmlEditorRef.current.instance.option('value')
            var response = await createOrUpdateNews(body, auth)
            if (response.success) {
                updateList()
            }
            else {
                handlePopapNotification(response.message)
            }
            setVisibleLoading(false)
        }
        else {
            handlePopapNotification("Ошибка сохранения новости")
        }

    }

    const publishCurrentNewsHandler = async () => {
        if (htmlEditorRef?.current?.instance) {
            setVisibleLoading(true)
            let body = current
            body.status = 1
            body.name = newsName
            body.newsBody = htmlEditorRef.current.instance.option('value')
            var response = await createOrUpdateNews(body, auth)
            if (response.success) {
                updateList()
            }
            else {
                handlePopapNotification(response.message)
            }
            setVisibleLoading(false)
        }
        else {
            handlePopapNotification("Ошибка сохранения новости")
        }

    }

    const deleteCurrentNewsHandler = async () => {
        setVisibleLoading(true)
        var response = await deleteCurrentNews(current.newsId, auth)
        if (response.success) {
            updateList()
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)

    }

    const onNewsNameChanged = (e) => {
        setNewsName(e.value)
    }

    return (
        <div style={{ marginRight: '10px' }}>
            <div style={{ marginLeft: '10px' }}>Название новости</div>
            <TextBox onValueChanged={onNewsNameChanged} ref={textBoxRef} placeholder={newsName ?? "Без названия"} style={{ marginBottom: '10px' }} />
            <div>
                <div style={{ marginBottom: '10px' }}>
                    <HtmlEditor ref={htmlEditorRef} defaultValue={newsBody} valueType="html">
                        <MediaResizing enabled={true} />
                        <ImageUpload tabs={['file', 'url']} fileUploadMode="base64" />
                        <TableContextMenu enabled={true} />
                        <TableResizing enabled={true} />
                        <Toolbar multiline={true}>
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
                            <Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="strike" />
                            <Item name="underline" />
                            <Item name="separator" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                            <Item name="alignJustify" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="separator" />
                            <Item name="header" acceptedValues={headerValues} options={headerOptions} />
                            <Item name="separator" />
                            <Item name="color" />
                            <Item name="background" />
                            <Item name="separator" />
                            <Item name="link" />
                            <Item name="image" />
                            <Item name="separator" />
                            <Item name="clear" />
                            <Item name="codeBlock" />
                            <Item name="blockquote" />
                            <Item name="separator" />
                            <Item name="insertTable" />
                            <Item name="deleteTable" />
                            <Item name="insertRowAbove" />
                            <Item name="insertRowBelow" />
                            <Item name="deleteRow" />
                            <Item name="insertColumnLeft" />
                            <Item name="insertColumnRight" />
                            <Item name="deleteColumn" />
                        </Toolbar>

                    </HtmlEditor>
                </div>
                <div className="news-button-bottom">
                    <Button onClick={updateCurrentNewsHandler} text="Сохранить как черновик" />
                    <Button onClick={publishCurrentNewsHandler} text="Опубликовать" />
                    <Button onClick={deleteCurrentNewsHandler} text="Удалить новость" />
                </div>
            </div>
        </div>
    )
}
export default CurrentNewsCreator