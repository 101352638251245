import { Button } from "devextreme-react"
import { Link } from "react-router-dom"
import { HiArchiveBoxXMark } from "react-icons/hi2";
import { HiDocument } from "react-icons/hi2";
import { useState, useEffect } from "react";

const RequestDisplayFile = ({request, deleteFile, status}) => {
    
    const [currentFile, setCurrenFile] = useState(null)

    useEffect(() => {
        setCurrenFile(request)
    }, [request])

    const deleteHandler = () => {
        deleteFile(currentFile)
    }
    
    return (
        <div style={{marginTop: '20px', display: 'flex', alignItems: 'center', pointerEvents: (status !== null && status >= 2) ? 'none' : 'auto', opacity: (status !== null && status >= 2) ? '0.5' : '1'}}>
            <HiDocument style={{width: '25px', height: '25px', marginRight: '10px'}}/>
            <div style={{fontSize: '20px', marginRight: '20px'}}>{currentFile?.name ?? '*Название отсутствует*'}</div>
            <HiArchiveBoxXMark onClick={deleteHandler} style={{width: '15px', height: '15px', marginRight: '10px', color: 'red', cursor: 'pointer'}}/>
        </div>
    )
}
export default RequestDisplayFile