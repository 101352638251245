const deleteAccount = async (id, auth) => {
    try {
        const apiUrl = process.env.REACT_APP_USER_DELETE + id;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + auth.token ?? "",
            'Content-Type': 'application/json'
          }
        });
    
        let data = null
    
        if (response.status === 200) {
          data = await response.json();
        }
        else if (response.status === 400) {
          data = {
            success: false,
            message: `Не удалось удалить аккаунт`,
            content: null
          }
        }
        else {
          data = {
            success: false,
            message: "Ошибка сервера при удаления аккаунта",
            content: null
          }
        }
    
        return data
    
      } catch (error) {
        let data = {
          success: false,
          message: "Не удается подключиться к серверу",
          content: null
        }
        return data
      }
}

export default deleteAccount