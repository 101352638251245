import { Button } from "devextreme-react"
import { useState, useEffect } from "react"
import { LoadPanel, Toast } from 'devextreme-react';
import DataGrid, { Column, FilterRow, Selection, LoadPanel as Loading, Paging, Pager } from 'devextreme-react/data-grid';
import AdminHeader from "../../../base-components/AdminHeader"
import MainHeader from "../../../base-components/MainHeader"
import { Form, SimpleItem, GroupItem, Label, ButtonItem } from 'devextreme-react/form';
import { useAuth } from "../../../hooks/useAuth";
import "./css/roles-style.css"
import getUserListService from "./services/getUserListService";
import RegisterUserPopup from "./components/RegisterUserPopup";
import CustomStore from "devextreme/data/custom_store";
import deleteAccount from "./services/deleteAccount";

const RolesList = () => {

    const initAccount = {
        type: 1,
        role: 1,
        inn: null,
        ogrnip: null,
        ogrn: null,
        username: null,
        password: null,
        password2: null
    }

    const initFilters = {
        inn: null,
        ogrn: null,
        ogrnip: null,
        email: null,
        kind: 0,
        regDateFrom: null,
        regDateTo: null,
        role: 0
    }

    const authTypes = [
        {
            name: 'Физ. лицо',
            type: 1,
        },
        {
            name: 'ИП',
            type: 2,
        },
        {
            name: 'Юр. лицо',
            type: 3,
        }
    ]

    const rolesInit = [
        {
            name: "Пользователь",
            type: 1
        },
        {
            name: "Администратор",
            type: 2
        },
        {
            name: "Менеджер",
            type: 3
        }
    ]

    const initRolesList = new CustomStore({
        key: 'id',
        async load(loadOptions) {
            return {
                data: [],
                totalCount: 0
            }
        }
    })

    const [filters, setFilters] = useState(initFilters)
    const [roles, setRoles] = useState(initRolesList)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [visiblePopup, setVisiblePopup] = useState(false)
    const [currentAccount, setCurrentAccount] = useState(initAccount)
    const [visibleDeleteButton, setVisibleDeleteButton] = useState(false)
    const [visibleShowButton, setVisibleShowButton] = useState(false)

    const auth = useAuth()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    useEffect(() => {
        document.title = "Аккаунты пользователей"
    }, [])

    useEffect(() => {
        getUserListHandler()
    }, [pageIndex, pageSize])

    const updateAgainHandler = () => {
        setPageIndex(0)
        getUserListHandler()
    }

    const getUserListHandler = async () => {
        setVisibleLoading(true)
        setVisibleShowButton(false)
        setVisibleDeleteButton(false)
        let body = {
            indexPage: pageIndex,
            pageSize: pageSize,
            filters: filters
        }
        var result = await getUserListService(body, auth);
        if (result.success) {
            let store = new CustomStore({
                key: 'id',
                async load(loadOptions) {
                    return {
                        data: result.content.data,
                        totalCount: result.content.totalCount
                    }
                }
            })
            setRoles(store)
        } else {
            setRoles(initRolesList)
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const renderKind = ({ data }) => {
        let display = 'Неизвестно'
        if (data?.kind === 1) {
            display = "Физ. лицо"
        }
        else if (data?.kind === 2) {
            display = "ИП"
        }
        else if (data?.kind === 3) {
            display = "Юр. лицо"
        }
        return (
            <span>{display}</span>
        )
    }

    const renderRole = ({ data }) => {
        let display = 'Неизвестно'
        if (data?.role === 1) {
            display = "Пользователь"
        }
        else if (data?.role === 2) {
            display = "Админ"
        }
        else if (data?.role === 3) {
            display = "Менеджер"
        }
        return (
            <span>{display}</span>
        )
    }

    const renderDate = ({ data }) => {
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        };

        let date = new Date(data.createAt)

        return (
            <span>{date.toLocaleDateString('ru-RU', options)}</span>
        )
    }

    const changeRowColor = (e) => {
        if (e.rowType === 'data') {
            if (e.data.role !== null && e.data.role !== 0) {
                if (e.data.role === 2) {
                    e.rowElement.style.backgroundColor = "LightBlue"
                }
                else if (e.data.role === 3) {
                    e.rowElement.style.backgroundColor = "LightGreen"
                }
            }
        }
    }

    const regNewAccount = () => {
        let body = {
            kind: 1,
            role: 1,
            inn: null,
            orgnip: null,
            ogrn: null,
            username: null,
            password: null,
            password2: null
        }
        setCurrentAccount(body)
        setVisiblePopup(true)
    }

    const showCurrentAccount = (e) => {
        setCurrentAccount(e.data)
        openRegisterPopup()
    }

    const openRegisterPopup = () => {
        if (currentAccount?.id !== auth?.userId){
            setVisiblePopup(true)
        }else{
            handlePopapNotification("Невозможно редактировать свой аккаунт")
        }
    }

    const onSelectionChanged = (e) => {
        setCurrentAccount(e.selectedRowsData[0])
        if (e != null && e.selectedRowsData != null && e.selectedRowsData.length > 0) {
            setVisibleDeleteButton(true)
            setVisibleShowButton(true)
        } else {
            setVisibleDeleteButton(false)
            setVisibleShowButton(false)
        }

    }

    const onDeleteAccount = async (id) => {
        if (currentAccount?.id !== auth?.userId) {
            setVisibleLoading(true)
            if (currentAccount?.id) {
                var result = await deleteAccount(currentAccount?.id, auth);
                if (result.success) {
                    updateAgainHandler()
                } else {
                    handlePopapNotification(result.message)
                }
            }
            setVisiblePopup(false)
            setVisibleLoading(false)
        }
        else{
            handlePopapNotification("Невозможно удалить свой аккаунт")
        }
    }

    return (
        <div>
            <MainHeader />
            <AdminHeader />
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <RegisterUserPopup auth={auth} registerData={currentAccount} visible={visiblePopup} onHide={() => setVisiblePopup(false)} updateList={updateAgainHandler} deleteAccount={onDeleteAccount} />
            <div className="roles-body-container">
                <div>
                    <Form formData={filters} colCount={2}>
                        <GroupItem >
                            <SimpleItem
                                dataField="inn"
                                editorType="dxTextBox"
                            >
                                <Label text={"ИНН"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="ogrn"
                                editorType="dxTextBox"
                            >
                                <Label text={"ОГРН"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="ogrnip"
                                editorType="dxTextBox"
                            >
                                <Label text={"ОГРНИП"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="email"
                                editorType="dxTextBox"
                            >
                                <Label text={"E-mail"} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem>
                            <SimpleItem
                                dataField="kind"
                                editorType="dxSelectBox"
                                editorOptions={{ placeholder: 'Выберите вид', dataSource: authTypes, valueExpr: "type", displayExpr: "name", showClearButton: true }}
                            >
                                <Label text={"Вид аккаунта"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="regDateFrom"
                                editorType="dxDateBox"
                                editorOptions={{ displayFormat: "dd.MM.yyyy", useMaskBehavior: true, showClearButton: true }}
                            >
                                <Label text={"Дата регистрации с"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="regDateTo"
                                editorType="dxDateBox"
                                editorOptions={{ displayFormat: "dd.MM.yyyy", useMaskBehavior: true, showClearButton: true }}
                            >
                                <Label text={"Дата регистрации по"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="role"
                                editorType="dxSelectBox"
                                editorOptions={{ placeholder: 'Выберите роль', dataSource: rolesInit, valueExpr: "type", displayExpr: "name", showClearButton: true }}
                            >
                                <Label text={"Роль"} />
                            </SimpleItem>

                        </GroupItem>

                    </Form>
                </div>
                <div className="roles-main-buttons-container">
                    <div className="roles-buttons-container">
                        <Button onClick={regNewAccount} text="Зарегистрировать нового пользователя" />
                        <Button onClick={openRegisterPopup} visible={visibleShowButton} text="Редактировать пользователя" />
                        <Button visible={visibleDeleteButton} onClick={async () => await onDeleteAccount(currentAccount?.id)} text="Удалить пользователя" />
                    </div>
                    <Button text="Обновить список" onClick={updateAgainHandler} />
                </div>

                <div>
                    <DataGrid
                        onSelectionChanged={onSelectionChanged}
                        onRowDblClick={showCurrentAccount}
                        onRowPrepared={changeRowColor}
                        dataSource={roles}
                        showBorders={true}
                        hoverStateEnabled={true}
                        keyExpr="id"
                        noDataText='Зарегистрированные пользователи отсутствуют'
                        rowAlternationEnabled={false}
                        showRowLines={true}
                        remoteOperations={true}
                    >
                        <FilterRow visible={false}/>
                        <Loading enabled={false} />
                        <Selection mode="single" />
                        <Column dataField="username" alignment="center" caption="E-mail" />
                        <Column dataField="inn" alignment="center" caption="ИНН" />
                        <Column dataField="orgnip" alignment="center" caption="ОГРНИП" />
                        <Column dataField="ogrn" alignment="center" caption="ОГРН" />
                        <Column dataField="kind" alignment="center" caption="Вид" cellRender={renderKind} />
                        <Column dataField="role" alignment="center" caption="Роль" cellRender={renderRole} />
                        <Column dataField="createAt" alignment="center" caption="Дата регистрации" cellRender={renderDate} />

                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 30, 50]}
                            showNavigationButtons={true}
                            visible={true}
                        />
                        <Paging
                            defaultPageSize={10}
                            onPageSizeChange={setPageSize}
                            onPageIndexChange={setPageIndex}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                        />
                    </DataGrid>
                </div>
            </div>
        </div>

    )
}
export default RolesList