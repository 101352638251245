import { Button, List, Popup, TextBox } from "devextreme-react"
import { useRef, useState } from "react"
import { ItemDragging } from "devextreme-react/cjs/list"

const CreatePagePopup = ({ onHide, visible, pageList, createOrUpdatePage, deletePage, reorder, setChoosed }) => {
    const [selectedPage, setSelectedPage] = useState(null)
    const [selectedPageName, setSelectedPageName] = useState(null)
    const textBxRef = useRef()
    const listRef = useRef()

    const onTextChanged = (e) => {
        setSelectedPageName(e.value)
    }

    const onClosing = () => {
        if (textBxRef?.current?.instance) {
            textBxRef.current.instance.option('value', null)
        }
        if (listRef?.current?.instance) {
            listRef.current.instance.unselectAll()
        }
        setSelectedPageName(null)
        setSelectedPage(null)
        onHide()
    }

    const createNewPage = () => {
        let body = {
            status: 0,
        }
        createOrUpdatePage(body)
    }

    const updateCurrentPage = () => {
        let body = selectedPage
        body.status = 0
        body.name = selectedPageName

        createOrUpdatePage(body)
    }

    const publishCurrentPage = () => {
        let body = selectedPage
        body.status = 1
        body.name = selectedPageName

        createOrUpdatePage(body)
    }

    const deletePageHandler = () => {
        setSelectedPage(null)
        setSelectedPageName(null)
        deletePage(selectedPage.pageId)
    }

    const choosePageHandler = () => {
        setChoosed(selectedPage)
        onClosing()
    }

    const itemRender = (e) => {
        return (
            <div>
                <div style={{ wordBreak: "break-all" }}>{e.name ?? "Без названия"}</div>
                {
                    e.status === 1 ? (
                        <div className="news-item-status">Статус: <div style={{ color: 'green', marginLeft: '10px' }}>Опубликовано</div></div>
                    ) : (
                        <div className="news-item-status">Статус: <div style={{ color: 'red', marginLeft: '10px' }}>Не опубликовано</div></div>
                    )
                }
            </div>
        )
    }

    const onSelectionChanged = (e) => {
        if (e.addedItems?.length !== 0) {
            setSelectedPage(e.addedItems[0])
            setSelectedPageName(e.addedItems[0].name)
        } else {
            setSelectedPage(null)
        }
    }

    const onOrderChanged = async (e) => {
        let body = pageList[e.fromIndex]
        body.order = e.toIndex
        reorder(body)
    }

    return (
        <Popup
            dragEnabled={false}
            title="Управление страницами"
            onHiding={onClosing}
            visible={visible}
            enableBodyScroll={false}
            width={'600'}
            height={'auto'}
            maxHeight={'90%'}>

            <div>
                {
                    selectedPage && (
                        <div style={{ marginBottom: '10px' }}>
                            <div style={{ marginLeft: '10px' }}>Название страницы</div>
                            <TextBox ref={textBxRef} onValueChanged={onTextChanged} placeholder={selectedPage.name ?? "Без названия"} style={{ marginBottom: "10px" }} />
                            <div className="pages-button-bottom">
                                <Button onClick={updateCurrentPage} text="Сохранить как черновик" />
                                <Button onClick={publishCurrentPage} text="Опубликовать" />
                                <Button onClick={deletePageHandler} text="Удалить страницу" />
                            </div>
                        </div>
                    )
                }

                <div style={{ marginBottom: '10px' }}>
                    <h3>
                        Список доступных страниц
                    </h3>
                    <Button onClick={createNewPage} style={{ marginBottom: '10px', width: '100%' }} text="Создать новую страницу" />
                    <List
                        ref={listRef}
                        noDataText="Отсутствуют созданные страницы"
                        dataSource={pageList}
                        itemRender={itemRender}
                        onSelectionChanged={onSelectionChanged}
                        selectByClick={true}
                        selectionMode={'single'}
                        scrollingEnabled={true}
                    >
                        <ItemDragging allowReordering={true} onDragEnd={onOrderChanged}>

                        </ItemDragging>
                    </List>
                </div>

                {
                    selectedPage && (
                        <div className="pages-button-bottom">
                            <Button onClick={choosePageHandler} text="Подтвердить выбор" />
                        </div>
                    )
                }

            </div>


        </Popup>
    )
}
export default CreatePagePopup