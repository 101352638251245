const reorderPages = async (body, auth) => {
    try {
        const apiUrl = process.env.REACT_APP_PAGE_REORDER;
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + auth.token ?? ""
            },
            body: JSON.stringify(body)
        });
        let data = null

        if (response.status === 200) {
            data = await response.json();
        }
        else if (response.status === 400) {
            data = {
                success: false,
                message: "Не удалось перестроить",
                content: null
            }
        }
        else {
            data = {
                success: false,
                message: "Ошибка перестроения на сервере",
                content: null
            }
        }

        return data

    } catch (error) {
        let data = {
            success: false,
            message: "Не удается подключиться к серверу",
            content: null
        }
        return data
    }
}
export default reorderPages