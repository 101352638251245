const deleteCurrentNews = async (id, auth) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_NEWS_DELETE+id;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.token ?? ""
        }
      });
      
      let data = null

      if (response.status === 200){
        data = await response.json();
      }
      else if (response.status === 400) {
        data = {
          success: false,
          message: `Не удалось удалить новость`,
          content: null
        }
      } 
      else {
        data = {
          success: false,
          message: "Ошибка сервера при удалении новости",
          content: null
        }
      }

      return data

    } catch (error) {
      let data = {
        success: false,
        message: "Не удается подключиться к серверу",
        content: null
      }
     return data
    }
  };

export default deleteCurrentNews;