import DocumentItem from "./DocumentItem";

const SectionViewer = ({ section }) => {

    function createMarkup() {
        if (section?.body) {
            return { __html: section.body };
        }
        else {
            return null
        }
    }

    return (
        <div>
            {
                section !== null && section.name !== null && (
                    <h1>{section.name}</h1>
                )
            }
            {
                section !== null && section.body !== null && (
                    <div dangerouslySetInnerHTML={createMarkup()}></div>
                )
            }
            {
                section !== null && section.documents !== null && section.documents.length !== 0 && (
                    <div>
                        {
                            section.documents.map(c => (
                                <DocumentItem currentDocument={c} />
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}

export default SectionViewer