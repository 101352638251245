import { Link, useNavigate } from "react-router-dom";
import Menu, { MenuTypes } from 'devextreme-react/menu';
import { Button, LoadPanel } from "devextreme-react";
import logo from "../pages/main/resourses/logo.png"
import "../base-components/css/base-header.css"
import AuthPopap from "./AuthPopap";
import { useState, useEffect } from "react";
import PageViewer from "../pages/main/components/PageViewer";
import getHeaderInfo from "../base-services/getHeaderInfo";
import getPageInfo from "../base-services/getPageIndo";
import { useAuth } from "../hooks/useAuth";

const MainHeader = () => {

    const [indexPages, setIndexPages] = useState([])
    const [logoText, setLogoText] = useState(null)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [visibleLogin, setVisibleLogin] = useState(false)
    const [visiblePage, setVisiblePage] = useState(false)
    const [currentPage, setCurrentPage] = useState(null)

    const auth = useAuth()
    const navigation = useNavigate()

    const broadcastChannel = new BroadcastChannel('action_channel');

    function handleMessage(event) {
        if (event.data.type === 'ACTION_PERFORMED') {
            console.log('Action performed in another tab');
            // Выполните нужное действие здесь
        }
    }

    broadcastChannel.addEventListener('message', handleMessage);
    broadcastChannel.removeEventListener('message', handleMessage);
    broadcastChannel.close();

    useEffect(() => {
        updateBaseInfo()

    }, [])

    const onHidePage = () => {
        setCurrentPage(null)
        setVisiblePage(false)
    }

    const onMenuClick = (e) => {
        if (e.itemData.pageId) {
            getPageInformatonHandler(e.itemData.pageId)
        }
    }

    const updateBaseInfo = async () => {
        setVisibleLoading(true)
        var result = await getHeaderInfo()
        if (result.success) {
            setIndexPages(result.content.indexPages)
            setLogoText(result.content.logoName)
        }
        setVisibleLoading(false)
    }

    const getPageInformatonHandler = async (id) => {
        setVisibleLoading(true)
        var result = await getPageInfo(id)
        if (result.success) {
            setCurrentPage(result.content)
            setVisiblePage(true)
        }
        setVisibleLoading(false)
    }

    const enterToAccount = () => {
        if (auth.role === 1) {
            navigation('/account/requests')
        }
        else if (auth.role === 2) {
            navigation('/admin/info')
        }
        else if (auth.role === 3) {
            navigation('/manager/requests')
        }
    }

    function handleMessage(event) {
        if (event.data.type === 'ACTION_LOGOUT') {
            navigation("/")
        }
    }

    return (
        <div className="header-container">
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <AuthPopap visible={visibleLogin} onHide={() => setVisibleLogin(false)} />
            <PageViewer visible={visiblePage} onHide={onHidePage} page={currentPage} />
            <Link className="link" to={"/"}>
                <div>
                    <img className="logo" src={logo} alt="SVG Image" />
                </div>
                <div className="logo-name" >
                    {logoText ?? "МУП \"ДВУРЕЧЬЕ\""}
                </div>
            </Link>

            <div className="right-header">
                <div className="multi-line-menu">
                    <Menu
                        onItemClick={onMenuClick}
                        dataSource={indexPages}
                        displayExpr="name"
                        noDataText=""
                    />
                </div>
                {
                    auth.isAuth ? (
                        <div>
                            <Button onClick={enterToAccount} text="Личный кабинет" className="login-button" />
                        </div>
                    ) : (
                        <div>
                            <Button onClick={() => setVisibleLogin(true)} text="Войти в личный кабинет" className="login-button" />
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default MainHeader