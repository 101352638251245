import { Form } from "devextreme-react"
import { GroupItem } from "devextreme-react/cjs/form"
import { useEffect, useState } from "react"
import "./css/account-style.css"
import RequestImage from "./components/RequestImage"
import MainHeader from "../../base-components/MainHeader"
import AccountHeader from "../../base-components/AccountHeader"
import { useAuth } from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import getRequestByUserId from "./services/getRequestByUserId"
import { LoadPanel, Toast } from 'devextreme-react';

const AccountAllRequests = () => {

    const [myRequests, setMyRequests] = useState(null)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)
    
    const auth = useAuth()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const getRequestsHandler = async () => {
        setVisibleLoading(true)
        var response = await getRequestByUserId(auth.userId, auth)
        if (response.success) {
            setTimeout(() => setMyRequests(Array.isArray(response.content) ? response.content : []))
        }
        setVisibleLoading(false)
    }

    useEffect(() => {
        getRequestsHandler()
        document.title = "Мои заявки"
    }, [])

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <MainHeader />
            <AccountHeader />
            <div className="account-body">
                <Form>
                    <GroupItem caption="Мои заявки" />
                </Form>
                <div>
                    {
                        (myRequests && Array.isArray(myRequests) && myRequests.length > 0) && (
                            myRequests.map(c => (
                                <div>
                                    <RequestImage auth={auth} request={c} setVisibleLoading={setVisibleLoading} handlePopapNotification={handlePopapNotification} updateRequests={getRequestsHandler}/>
                                    <hr style={{ opacity: '0.35', marginBottom: '15px', marginTop: '15px' }} />
                                </div>
                            ))
                        )
                    }
                </div>
            </div>
        </div>

    )
}
export default AccountAllRequests