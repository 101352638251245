import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const AdminPrivateRoute = ({element}) => {
    const auth = useAuth()

    return (
        auth.isAuth && auth.role === 2 ? (
          element
        ) : (
          <Navigate to="/" />
        )
      );
}

export default AdminPrivateRoute