import { Button } from "devextreme-react"
import { useEffect, useState } from "react"
import SectionItem from "./SectionItem"
import "../css/pages-style.css"
import createOrUpdateSection from "../services/createOrUpdateSection"
import displaySections from "../services/displaySections"
import deleteSection from "../services/deleteSection"
import { useAuth } from "../../../../hooks/useAuth"

const PageContent = ({selectedPage, setLoading, notofication}) => {
    
    const auth = useAuth()

    const [sectionList, setsectionList] = useState([])

    useEffect(() => {
        displaySectionsHandler()
    }, [selectedPage])
    
    
    const createSectionHandler = async () => {
        let body = {
            pageId: selectedPage.pageId
        }
        setLoading(true)
        var result = await createOrUpdateSection(body, auth)
        if (result.success) {
            let sections = sectionList
            sections.push(result.content)
            setsectionList(sections)
        } else {
            notofication(result.message)
        }
        setLoading(false)
    }

    const displaySectionsHandler = async () => {
        setLoading(true)
        var result = await displaySections(selectedPage.pageId, auth)
        if (result.success) {
            setsectionList(result.content)
        } else {
            notofication(result.message)
        }
        setLoading(false)
    }

    const updateSectionHandler = async (body) => {
        setLoading(true)
        var result = await createOrUpdateSection(body, auth)
        if (result.success) {
            
        } else {
            notofication(result.message)
        }
        setLoading(false)
    }

    const deleteSectionHandler = async (id) => {
        setLoading(true)
        let newSections = sectionList.filter(c => c.sectionId !== id)
        setsectionList(newSections)
        var result = await deleteSection(id, auth)
        if (result.success) {
            
        } else {
            notofication(result.message)
        }
        setLoading(false)
    }

    
    return (
        <div>
            <h1>{selectedPage.name ?? "*Название отсутствует*"}</h1>
            <div>
                {
                    sectionList !== null && sectionList.length !== 0 ? (
                        sectionList.map(c => (
                            <div>
                                <SectionItem section={c} 
                                updateSection={updateSectionHandler} 
                                deleteSection={deleteSectionHandler}
                                setLoading={setLoading}
                                notification={notofication}/>
                                <hr style={{marginTop: '20px'}}/>
                            </div>
                        ))
                    )
                    
                    :(
                        <div>Секции отсутствуют</div>
                    )
                }
            </div>
            <div>
                <Button onClick={createSectionHandler} style={{marginTop: '20px'}} text="Создать новую секцию"/>
            </div>
        </div>
    )
}
export default PageContent