import { List, Button } from "devextreme-react"
import { ItemDragging } from "devextreme-react/cjs/list"
import { useState, useEffect } from "react"

const IndexPages = ({reorder, indexPages, setSelected, createIndexPage }) => {

    const [indexPagesList, setIndexPagesList] = useState([])

    const itemRender = (e) => {
        return (
            <div>
                <div style={{ wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal' }}>{e.name ?? "Без названия"}</div>
                {
                    e.status === 1 ? (
                        <div className="news-item-status">Статус: <div style={{ color: 'green', marginLeft: '10px' }}>Опубликовано</div></div>
                    ) : (
                        <div className="news-item-status">Статус: <div style={{ color: 'red', marginLeft: '10px' }}>Не опубликовано</div></div>
                    )
                }
            </div>
        )
    }

    const onSelectionChanged = (e) => {
        if (e.addedItems?.length !== 0) {
            setSelected(e.addedItems[0])
        } else {
            setSelected(null)
        }
    }

    useEffect(() => {
        setIndexPagesList(indexPages)
    }, [indexPages])

    const createIndexPageHandle = () => {
        let body = {
            status: 0
        }
        createIndexPage(body)
    }

    const onOrderChanged = async (e) => {
        let body = indexPagesList[e.fromIndex]
        body.order = e.toIndex
        reorder(body)
    }


    return (
        <div>
            <h3>Список разделов</h3>
            <Button onClick={createIndexPageHandle} style={{ marginBottom: '10px', width: '100%' }} text="Создать новый раздел" />
            <List
                dataSource={indexPages}
                itemRender={itemRender}
                selectByClick={true}
                selectionMode={'single'}
                onSelectionChanged={onSelectionChanged}
                noDataText="Разделы отсутствуют"
            >
                <ItemDragging allowReordering={true} onDragEnd={onOrderChanged}>

                </ItemDragging>
            </List>
        </div>

    )
}

export default IndexPages