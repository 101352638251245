import { Button, TextArea } from "devextreme-react"
import { Popup } from "devextreme-react"
import { useEffect, useRef, useState } from "react";
import { LoadPanel, Toast } from 'devextreme-react';
import downloadDopFiles from "../../account/services/downloadDopFiles";
import downloadSignatureService from "../../account/services/downloadSignatureService";
import downloadSignedPdfService from "../../account/services/downloadSignedPdfService";
import downloadPdfService from "../../account/services/downloadPdfService";
import approveRequestService from "../services/approveRequestService"
import rejectRequestService from "../services/rejectRequestService"

const ShowRequestPopup = ({ visible, onHide, requestData, updateList, auth, deleteRequest}) => {

    const [currentRequest, setCurrentRequest] = useState(requestData)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const [displayUserType, setDisplayUserType] = useState(null)
    const [displayStatus, setDisplayStatus] = useState('В процессе')
    const [displayRequestStatusColor, setDisplayRequestStatusColor] = useState('Black')
    const [displayRequestKind, setDisplayRequestKind] = useState('Вид договора не указан')
    const [displayDate, setDisplayDate] = useState('Дата не указана')
    const [messageText, setMessageText] = useState(null);

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const HidePopup = () => {
        setCurrentRequest(null)
        setMessageText(null)
        onHide()
    }

    useEffect(() => {
        if (visible === true) {
            setCurrentRequest(requestData)
            initDisplay(requestData)
        }
    }, [visible, requestData])

    const initDisplay = (newRequest) => {
        if (newRequest?.status) {
            if (newRequest?.status === 2) {
                setDisplayStatus("Заявка сформирована")
                setDisplayRequestStatusColor("DarkBlue")
            }
            else if (newRequest?.status === 3) {
                setDisplayStatus("Заявка подписана")
                setDisplayRequestStatusColor("DarkBlue")
            }
            else if (newRequest?.status === 4) {
                setDisplayStatus("Заявка отправлена")
                setDisplayRequestStatusColor("DarkOrange")
            }
            else if (newRequest?.status === 5) {
                setDisplayStatus("Заявка одобрена")
                setDisplayRequestStatusColor("DarkGreen")
            }
            else if (newRequest?.status === 6) {
                setDisplayStatus("Заявка отклонена")
                setDisplayRequestStatusColor("FireBrick")
            } else {
                setDisplayStatus("Ошибка создания")
                setDisplayRequestStatusColor("FireBrick")
            }
        }
        else {
            setDisplayStatus("Ошибка создания")
            setDisplayRequestStatusColor("FireBrick")
        }
        if (newRequest?.kind) {
            if (newRequest?.kind == 1) {
                setDisplayRequestKind("Заявка о предоставлении технических условий на технологическое присоединение")
            }
            else if (newRequest?.kind == 2) {
                setDisplayRequestKind("Заявка на заключение договора о подключении к системе теплоснабжения")
            } else {
                setDisplayRequestKind("Вид договора не указан")
            }
        }
        else {
            setDisplayRequestKind("Вид договора не указан")
        }

        if (newRequest?.userType) {
            if (newRequest?.userType == 1) {
                setDisplayUserType("Физ. лицо")
            }
            else if (newRequest?.userType == 2) {
                setDisplayUserType("ИП")
            }
            else if (newRequest?.userType == 3) {
                setDisplayUserType("Юр. лицо")
            }
            else {
                setDisplayUserType(null)
            }
        }
        else {
            setDisplayUserType(null)
        }

        if (newRequest?.createAt) {
            try {
                let options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                }

                let date = new Date(newRequest?.createAt)
                setDisplayDate(date.toLocaleDateString('ru-RU', options))
            }
            catch {
                setDisplayDate("Дата не указана")
            }
        }
        else {
            setDisplayDate("Дата не указана")
        }
    }

    const normaliseDate = (date) => {
        try {
            let options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }

            let dateObject = new Date(date)
            return dateObject.toLocaleDateString('ru-RU', options)
        }
        catch {
            return "Дата не указана"
        }
    }

    const downloadPdfHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadPdfService(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `request-${currentRequest.requestNumber}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadSignedPdfHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadSignedPdfService(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `request_signed-${currentRequest.requestNumber}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadDopFilesHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadDopFiles(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `files-${currentRequest.requestNumber}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadSignatureHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadSignatureService(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `sign-${currentRequest.requestNumber}.p7s`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const aprooveHandler = async () => {
        setVisibleLoading(true)
        let body = {
            requestId: currentRequest?.requestId,
            message: messageText,
            author: auth.username
        }
        var result = await approveRequestService(body, auth)
        if (result.success) {
            HidePopup()
            updateList()
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const rejectHandler = async () => {
        setVisibleLoading(true)
        let body = {
            requestId: currentRequest?.requestId,
            message: messageText,
            author: auth.username
        }
        var result = await rejectRequestService(body, auth)
        if (result.success) {
            HidePopup()
            updateList()
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const deleteRequestHandler = async () => {
        await deleteRequest(currentRequest?.requestId)
        HidePopup()
    }

    const onMessageTextChangedHandler = (e) => {
        setMessageText(e.value)
    }

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <Popup
                dragEnabled={false}
                visible={visible}
                onHiding={HidePopup}
                title={"Просмотр заявки"}
                enableBodyScroll={false}
                height={'90%'}
            >
                <div>
                    <div className="manager-element-header">
                        <div className="manager-element-small">
                            <div className="manager-element-header-text">Заявка № {currentRequest?.requestNumber ?? null}</div>
                            <div className="manager-element-header-text">({displayUserType})</div>
                        </div>
                        <div className="manager-element-status" style={{ borderColor: displayRequestStatusColor, color: displayRequestStatusColor }}>{displayStatus}</div>
                    </div>

                    <div>{displayRequestKind}</div>
                    <div style={{ marginTop: '10px' }}>{displayDate}</div>
                    <p>Документы:</p>
                    <div className="request-element-small">
                        <Button onClick={downloadPdfHandler} visible={currentRequest?.status == 2} text="Скачать заявку в PDF/A" />
                        <Button onClick={downloadSignedPdfHandler} visible={currentRequest?.status >= 3} text="Скачать подписанную заявку в PDF/A" />
                        <Button onClick={downloadSignatureHandler} visible={currentRequest?.status >= 3} text="Скачать подпись" />
                        <Button onClick={downloadDopFilesHandler} visible={currentRequest?.hasDopFiles} text="Скачать прикрепленные файлы" />
                    </div>
                    <div>
                        {
                            currentRequest?.messages && currentRequest?.messages?.lenght !== 0 && (
                                currentRequest.messages.map(c => (
                                    <div style={{ marginBottom: '10px' }}>
                                        <div className="manager-message-header">
                                            <div>{normaliseDate(c?.createAt)}</div>
                                            <b>{c?.author}</b>
                                        </div>
                                        <div className="manager-message-border">
                                            <div className="manager-message-text">{c?.text}</div>
                                        </div>
                                    </div>
                                ))
                            )
                        }

                    </div>
                    <div>
                        <TextArea visible={currentRequest?.status && currentRequest?.status === 4} value={messageText} onValueChanged={onMessageTextChangedHandler} height='100px' placeholder="Введите сообщение..." />
                    </div>
                    <div className="manager-bottom-buttons">
                        <div className="manager-bottom-buttons-start">
                            <Button onClick={aprooveHandler} visible={currentRequest?.status && currentRequest?.status === 4} type="success" text="Одобрить" />
                            <Button onClick={rejectHandler} visible={currentRequest?.status && currentRequest?.status === 4} type="danger" text="Отклонить" />
                        </div>
                        <Button onClick={deleteRequestHandler} text="Удалить заявку" />
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default ShowRequestPopup