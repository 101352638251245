const createOrUpdateRequest = async (dto, documets, auth) => {
    try {
        const formData = new FormData();
        formData.append('dto', JSON.stringify(dto));

        documets.forEach((file) => {
            formData.append('documents', file);
        })
        
        const apiUrl = process.env.REACT_APP_ACCOUNT_CREATE_OR_UPDATE_REQUEST;
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token ?? ""
            },
            body: formData
        });
        let data = null

        if (response.status === 200) {
            data = await response.json();
        }
        else if (response.status === 400) {
            data = {
                success: false,
                message: "Не удалось сохранить заявку",
                content: null
            }
        }
        else {
            data = {
                success: false,
                message: "Ошибка сохранения файла на сервере",
                content: null
            }
        }

        return data

    } catch (error) {
        let data = {
            success: false,
            message: "Не удается подключиться к серверу",
            content: null
        }
        return data
    }
}
export default createOrUpdateRequest