import { Button } from "devextreme-react"
import { FaFileDownload } from "react-icons/fa";
import { LoadPanel, Toast } from 'devextreme-react';
import { useState } from "react";
import downloadFile from "../../admin/pages/services/downloadFile"

const DocumentItem = ({ currentDocument }) => {

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)
    
    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const downloadFileHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadFile(currentDocument.documentId)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', currentDocument.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    return (
        <div style={{marginTop: '10px'}}>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}/>
            <Button onClick={downloadFileHandler} style={{padding: '0px 5px'}}>
                <FaFileDownload />
                <div style={{marginLeft: '5px'}}>
                    {currentDocument.name}
                </div>
            </Button>
        </div>
    )
}

export default DocumentItem