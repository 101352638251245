import { Form, SimpleItem, GroupItem, Label, ButtonItem, EmailRule } from 'devextreme-react/form';
import { LoadPanel, Toast } from 'devextreme-react';
import MainHeader from '../../base-components/MainHeader';
import AccountHeader from '../../base-components/AccountHeader';
import "./css/account-style.css"
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import CryptoJS from 'crypto-js';
import updateAccountByUser from './services/updateAccountByUser';
import Cookies from 'js-cookie';

const AccoutPageProfile = () => {

    const auth = useAuth()
    const initialUser = {
        username: auth.username,
        password: "",
        password2: ""
    }

    useEffect(() => {
        document.title = "Профиль"
    }, [])

    
    const [account, setAccount] = useState(initialUser)
    const [modeAuthButton, setModeAuthButton] = useState('password')
    const [errorData, setErrorData] = useState([])

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationSuccess, setPopupNotificationSuccess] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const showAuthButton = () => {
        if (modeAuthButton === 'password') {
            setModeAuthButton('text')
        } else {
            setModeAuthButton('password')
        }
    }

    const checkPasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength += 1; // Минимальная длина 8 символов
        if (/[A-Z]/.test(password)) strength += 1; // Наличие заглавных букв
        if (/[a-z]/.test(password)) strength += 1; // Наличие строчных букв
        if (/\d/.test(password)) strength += 1; // Наличие цифр
        if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) strength += 1; // Наличие специальных символов
        return strength;
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateAuthForm = async () => {

        let errors = []

        if (isEmptyOrSpaces(account?.username)) {
            errors.push("E-mail не может быть пустым")
        } else {
            let validMail = validateEmail(account?.username)
            if (!validMail) {
                errors.push("Неверно указан E-mail")
            }
        }
        if (isEmptyOrSpaces(account?.password)) {
            errors.push("Пароль не может быть пустым")
        } else {
            let stPassword = checkPasswordStrength(account?.password)
            if (stPassword < 5) {
                errors.push("Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы")
            }
        }
        if (account?.password !== account?.password2) {
            errors.push("Пароли не совпадают")
        }

        return errors
    }

    const isEmptyOrSpaces = (str) => {
        return str === null || str.match(/^ *$/) !== null;
    }

    const updateAccountHandler = async () => {
        let errors = await validateAuthForm()
        setErrorData(errors)
        if (errors !== null && errors?.length !== 0) {
            return
        }

        const hash = CryptoJS.SHA256(account.password).toString();
        let body = {
            username: account.username,
            password: hash,
            userId: auth.userId
        }

        setVisibleLoading(true)
        
        var response = await updateAccountByUser(body, auth)
        if (response.success) {
            setToken(response.content)
            setPopupNotificationSuccess(true)
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    const setToken = (data) => {
        Cookies.set('token', JSON.stringify(data), { expires: 1 });
    }

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotificationSuccess}
                message={'Успешное изменение данных'}
                type={'success'}
                displayTime={2000}
                onHiding={() => setPopupNotificationSuccess(false)}
            />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <MainHeader />
            <AccountHeader />
            <div className='account-body'>
                <Form formData={account}>
                    <GroupItem caption="Изменить логин или пароль">
                        <SimpleItem
                            dataField="username"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'E-mail' }}>

                            <Label text={"E-mail"} />
                            <EmailRule message="Неверно введен E-mail" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="password"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Пароль', mode: modeAuthButton, buttons: [{ name: "password", location: 'after', options: { icon: 'eyeopen', stylingMode: 'text', onClick: showAuthButton } }] }}>

                            <Label text={"Пароль"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="password2"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Повторите пароль', mode: modeAuthButton, buttons: [{ name: "password", location: 'after', options: { icon: 'eyeopen', stylingMode: 'text', onClick: showAuthButton } }] }}>

                            <Label text={"Повторите пароль"} />
                        </SimpleItem>
                        <GroupItem visible={errorData?.length !== 0} render={() => (
                            errorData?.length !== 0 &&
                            <div style={{ marginTop: '10px', justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                                {
                                    errorData.map(c => (
                                        <div style={{ color: 'red' }}>{c}</div>
                                    ))
                                }
                            </div>
                        )}>

                        </GroupItem>
                        <ButtonItem buttonOptions={{ text: "Сохранить учетные данные", onClick: updateAccountHandler}} />
                    </GroupItem>
                </Form>
            </div>

        </div >
    )
}

export default AccoutPageProfile