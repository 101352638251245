
const NewsUnit = ({ news }) => {
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    let date = new Date(news.createAt)

    function createMarkup() {
        return { __html: news.newsBody };
    }

    return (
        <div>
            <h1 className="news-unit-header">{news.name}</h1>
            <div className="news-unit-date">{date.toLocaleDateString('ru-RU', options)}</div>
            <div className="news-unit-content" dangerouslySetInnerHTML={createMarkup()}></div>
            <hr/>
        </div>
    )
}

export default NewsUnit