import { Form, SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { Link, useNavigate } from 'react-router-dom';
import MainHeader from '../../base-components/MainHeader';
import AccountHeader from '../../base-components/AccountHeader';
import "./css/account-style.css"
import { Button, CheckBox } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { LoadPanel, Toast } from 'devextreme-react';
import RequestDisplayFile from './components/RequestDisplayFile';
import UploadRequestFile from './components/UploadRequestFile';
import createOrUpdateRequest from './services/createOrUpdateRequest';
import { useAuth } from '../../hooks/useAuth';
import { animateScroll as scroll } from 'react-scroll';
import downloadPdfService from './services/downloadPdfService';
import SignDocumentPopup from './components/SignDocumentPopup';
import downloadSignedPdfService from './services/downloadSignedPdfService';
import downloadSignatureService from './services/downloadSignatureService';
import sendFinishRequest from './services/sendFinishRequest';

const AccoutPageRequestTechnical = () => {

    const auth = useAuth()
    const navigate = useNavigate()
    const [currentFiles, setCurrentFiles] = useState([])
    const [uploadFileVisible, setUploadFileVisible] = useState(false)
    const [request, setRequest] = useState({ kind: 1, userType: auth.kind, status: 0, userId: auth.userId })

    const [isChecked, setIsChecked] = useState(false)
    const [isCheckedTruth, setIsCheckedTruth] = useState(false)

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const [visibleSignPopup, setVisibleSignPopup] = useState(false)

    const onCheckedPrivate = (e) => {
        scrollToBottom()
        setIsChecked(e.value)
    }

    useEffect(() => {
        document.title = "Создание заявки о предоставлении технических условий на технологическое присоединение"
    }, [])

    const onCheckedTruth = (e) => {
        scrollToBottom()
        setIsCheckedTruth(e.value)
    }

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
    };

    const kvartalData = ['I', 'II', 'III', 'IV']

    const addRequestFile = (file) => {
        let files = currentFiles
        files.push(file)
        setCurrentFiles(files)
        scrollToBottom()
    }

    const deleteRequestFile = (file) => {
        let files = currentFiles
        let deleted = files.filter((c) => c !== file)
        setCurrentFiles(deleted)
        scrollToBottom()
    }

    const createRequestHandler = async () => {
        scrollToBottom()
        setVisibleLoading(true)
        let newRequest = request
        newRequest.status = 0
        var response = await createOrUpdateRequest(newRequest, currentFiles, auth)
        if (response.success) {
            setRequest(response.content)
            scrollToBottom()
            // navigate("/account/requests")
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    const openFileDialog = () => {
        setUploadFileVisible(true)
        scrollToBottom()
    }

    const closeFileDioalog = () => {
        setUploadFileVisible(false)
        scrollToBottom()
    }

    const downloadPdfHandler = async () => {
        scrollToBottom()
        setVisibleLoading(true)
        var result = await downloadPdfService(request.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `request-${request.requestNumber}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadSignedPdfHandler = async () => {
        scrollToBottom()
        setVisibleLoading(true)
        var result = await downloadSignedPdfService(request.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `request_signed-${request.requestNumber}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadSignatureHandler = async () => {
        scrollToBottom()
        setVisibleLoading(true)
        var result = await downloadSignatureService(request.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `sign-${request.requestNumber}.p7s`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const closeSignPopup = () => {
        setVisibleSignPopup(false)
        scrollToBottom()
    }

    const openSignPopup = () => {
        setVisibleSignPopup(true)
        scrollToBottom()
    }

    const sendRequestHandler = async () => {
        scrollToBottom()
        setVisibleLoading(true)
        var response = await sendFinishRequest(request.requestId, auth)
        if (response.success) {
            setRequest(response.content)
            setVisibleLoading(false)
            navigate("/account/requests")
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    return (
        <div>
            <MainHeader />
            <AccountHeader />
            <SignDocumentPopup auth={auth} visible={visibleSignPopup} request={request} onHide={closeSignPopup} setRequest={setRequest} />
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <UploadRequestFile addRequestFile={addRequestFile} visible={uploadFileVisible} onHide={closeFileDioalog} />
            <div className='requests-body'>
                <div style={{ fontSize: '14px' }}>
                    <p className='warning-account-message'>ОБРАТИТЕ ВНИМАНИЕ! Для подписания заявки электронной подписью необходимо установить <Link target="_blank" style={{ marginLeft: '5px' }} to="https://www.cryptopro.ru/products/cades/plugin">КриптоПро ЭЦП Browser plug-in!</Link></p>
                    <div style={{ marginBottom: '20px' }}>
                        <div>Инструкция по отправке заявки:</div>
                        <div>1. Заполнить поля</div>
                        <div>2. Загрузить необходимые приложения</div>
                        <div>3. Сохранить заявку, нажав соответствующую кнопку</div>
                        <div>4. Подписать документ, нажав соответствующую кнопку</div>
                        <div>5.1. В открывшемся окне выбрать сертификат и нажать "Подписать"</div>
                        <div>5. Нажать кнопку "Отправить"</div>
                        <div>P.S. Пока не нажата кнопка "Отправить", заявку видите только Вы</div>
                        <div>----</div>
                        <div>Вы можете создать документ, а подписание отложить. Для этого:</div>
                        <div>1. Заполняете поля</div>
                        <div>2. Загрузить необходимые приложения</div>
                        <div>3. Нажимаете кнопку "Сохранить заявку"</div>
                        <div>4. Переходите на вкладу "Мои заявки", там можно будет подписать и отправить</div>
                    </div>
                </div>
                <Form formData={request} labelMode='floating'>
                    <GroupItem caption="Заявка о предоставлении технических условий на технологическое присоединение">
                        <SimpleItem
                            dataField="fromName"
                            editorType="dxTextBox"
                        >
                            <Label text={"Заявление от"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="fromPhone"
                            editorType="dxTextBox"
                        >

                            <Label text={"№ тел. заявителя"} />
                        </SimpleItem>

                        {/* ФИЗИЧЕСКОЕ ЛИЦО */}
                        <GroupItem visible={auth.kind === 1} caption='Информация о заявителе - физическом лице'>
                            <SimpleItem
                                dataField="fizName"
                                editorType="dxTextBox"
                            >
                                <Label text={"ФИО заказчика - физ. лица"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="adress"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}>

                                <Label text={"Адрес регистрации по паспорту"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="passport"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}>
                                <Label text={"Паспортные данные: серия, номер, орган, выдавший документ, дата выдачи, код подразделения"} />
                            </SimpleItem>
                        </GroupItem>

                        {/* ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ */}
                        <GroupItem visible={auth.kind === 2} caption='Информация о заявителе - индивидуальном предпринимателе'>
                            <SimpleItem
                                dataField="ipName"
                                editorType="dxTextBox"
                            >
                                <Label text={"ФИО заказчика - индивидуального предпринимателя"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="ogrnip"
                                editorType="dxTextBox"
                            >

                                <Label text={"ОГРНИП"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="ogrnipDate"
                                editorType="dxDateBox"
                                editorOptions={{ width: '100%', displayFormat: "dd.MM.yyyy", useMaskBehavior: true }}
                            >
                                <Label text={"Дата внесения в ЕГРИП"} />
                            </SimpleItem>
                        </GroupItem>

                        {/* ЮРИДИЧЕСКОЕ ЛИЦО */}
                        <GroupItem visible={auth.kind === 3} caption='Информация о заявителе - юридическом лице'>
                            <SimpleItem
                                dataField="shortOrgName"
                                editorType="dxTextBox"
                            >

                                <Label text={"Сокращенное название организации"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="fullOrgName"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}>

                                <Label text={"Полное название организации"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="ogrn"
                                editorType="dxTextBox"
                            >
                                <Label text={"ОГРН"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="ogrnDate"
                                editorType="dxDateBox"
                                editorOptions={{ width: '100%', displayFormat: "dd.MM.yyyy", useMaskBehavior: true }}
                            >
                                <Label text={"Дата внесения в ЕГРЮЛ"} />
                            </SimpleItem>
                        </GroupItem>

                        <GroupItem caption='Контакты заявителя'>
                            <SimpleItem
                                dataField="postalAdress"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}
                            >

                                <Label text={"Почтовый адрес"} />
                            </SimpleItem>

                            <SimpleItem
                                dataField="contacts"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}>

                                <Label text={"Контактные данные: телефон, факс, адрес электронной почты и т.п."} />
                            </SimpleItem>
                        </GroupItem>

                        <GroupItem caption='Информация об объекте'>
                            <SimpleItem
                                dataField="object"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}>

                                <Label text={"Правовые основания владения, наименование объекта, отдельных зданий, сооружений, помещений в составе объекта"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="objectAdress"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}>

                                <Label text={"Адрес или место расположения объекта"} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="objectCharacter"
                                editorType="dxTextArea"
                                editorOptions={{ height: '70px' }}>

                                <Label text={"Краткая характеристика, назначение или предполагаемое использование объекта, отдельных зданий, сооружений, помещений в составе объекта, этажность"} />
                            </SimpleItem>
                        </GroupItem>

                    </GroupItem>
                    <GroupItem caption='Прикрепленные файлы' render={() => (
                        <div>
                            <div>
                                <div>К заявке должны быть приложены:</div>
                                <div>1. Копии учредительных документов, а также документы, подтверждающие полномочия лица, подписавшего заявление</div>
                                <div>2. Копии правоустанавливающих документов на земельный участок и объект капитального строительства или энергопринимающего устройства</div>
                                <div>3. Ситуационный план расположения объекта капитального строительства с привязкой к территории населенного пункта</div>
                                <div>4. Топографическая карта земельного участка в масштабе 1:500 с указанием всех наземных и подземных коммуникаций и сооружений, согласованная с организациями, эксплуатирующими указанные объекты (не прилагается, если заказчик - физическое лицо, осуществляющее создание (реконструкцию) объекта индивидуального жилищного строительства)</div>
                                <div>5. Расчет максимальных часовых и среднечасовых расходов тепловой энергии и соответствующих им расчетных расходов теплоносителей на технологические нужды, отопление, вентиляцию, кондиционирование воздуха и горячее водоснабжение на каждый подключаемый объект</div>
                            </div>

                            <div>
                                {
                                    currentFiles !== null && currentFiles.length !== 0 && (
                                        currentFiles.map(c => (
                                            <RequestDisplayFile deleteFile={deleteRequestFile} request={c} status={request?.status}/>
                                        ))
                                    )
                                }
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Button disabled={request?.status >= 2} text='Загрузить файл' onClick={openFileDialog} />
                            </div>

                        </div>
                    )}>

                    </GroupItem>

                    <GroupItem render={() => (
                        <div >
                            <div style={{ alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                <CheckBox
                                    disabled={request?.status >= 2}
                                    onValueChanged={onCheckedPrivate}
                                    value={isChecked}
                                />
                                <div style={{ marginLeft: '5px', marginRight: '5px' }}>Разделяю </div>
                                <Link target="_blank" to='/privacy-policy'>политику обработки персональных данных</Link>
                            </div>
                            <div style={{ alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                <CheckBox
                                    disabled={request?.status >= 2}
                                    onValueChanged={onCheckedTruth}
                                    value={isCheckedTruth}
                                />
                                <div style={{ marginLeft: '5px', marginRight: '5px' }}>Гарантирую, что представленная мной информация является полной, точной и достоверной, а также что при представлении информации не нарушаются действующее законодательство Российской Федерации, законные права и интересы третьих лиц</div>
                            </div>
                        </div>
                    )}>

                    </GroupItem>

                    <GroupItem render={() => (
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                            <Button disabled={request?.status >= 4 || (!isCheckedTruth || !isChecked)} onClick={createRequestHandler} text={request?.status >= 2 ? 'Сохранить заявку снова' : 'Сохранить заявку'} />
                            <Button disabled={request?.status >= 4} onClick={downloadPdfHandler} visible={request?.status == 2} text='Скачать заявку в PDF/A' />
                            <Button disabled={request?.status >= 4} onClick={openSignPopup} visible={request?.status >= 2 && request?.status < 4} text= {request?.status >= 3 ? 'Переподписать документ' : 'Подписать документ'} />
                            <Button disabled={request?.status >= 4} onClick={downloadSignedPdfHandler} visible={request?.status >= 3} text='Скачать подписанную заявку в PDF/A' />
                            <Button disabled={request?.status >= 4} onClick={downloadSignatureHandler} visible={request?.status >= 3} text='Скачать открепленную подпись' />
                            <Button disabled={request?.status !== 3 || request?.status >= 4} onClick={sendRequestHandler} text='Отправить заявку' />
                        </div>
                    )}>
                    </GroupItem>
                </Form>
            </div>

        </div >
    )
}

export default AccoutPageRequestTechnical