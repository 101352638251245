import { Form, SimpleItem, GroupItem, Label, ButtonItem } from 'devextreme-react/form';
import MainHeader from '../../../base-components/MainHeader';
import AdminHeader from '../../../base-components/AdminHeader';
import { useState, useEffect } from 'react';
import { LoadPanel, Toast } from 'devextreme-react';
import getBaseInfo from '../../../base-services/getBaseInfo';
import updateBaseInfo from './services/updateBaseInfo';
import { useAuth } from '../../../hooks/useAuth';

const InfoRedactor = () => {

    const baseInfoInit = {
        id: null,
        baseInformationId: null,
        logoText: null,
        bigText: null,
        smallText: null,
        adress: null,
        phone: null,
        email: null,
        dolgota: null,
        shirota: null
    }

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [baseInfo, setBaseInfo] = useState(baseInfoInit)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupSuccessNotification, setPopupSuccessNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const auth = useAuth()

    const getBaseInfoHandler = async () => {
        setVisibleLoading(true)
        var response = await getBaseInfo()
        if (response.success) {
            if (response.content !== null){
                setBaseInfo(response.content)
            }
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const updateBaseInfoHandler = async () => {
        setVisibleLoading(true)
        var response = await updateBaseInfo(baseInfo, auth)
        if (response.success) {
            if (response.content !== null){
                setBaseInfo(response.content)
            }
            setPopupSuccessNotification(true)
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    useEffect(() => {
        document.title = "Основная информация"
        getBaseInfoHandler()
    }, [])

    return (
        <div >
            <MainHeader />
            <AdminHeader />
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <Toast
                visible={popupSuccessNotification}
                message={"Успешное обновление информации!"}
                type={'success'}
                displayTime={2000}
                onHiding={() => setPopupSuccessNotification(false)}
            />
            <div style={{ padding: '50px' }}>
                <Form formData={baseInfo}>
                    <GroupItem caption="Основная информация">
                        <SimpleItem
                            dataField="logoText"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Текст логотипа' }}>

                            <Label text={"Текст логотипа"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="bigText"
                            editorType="dxTextArea"
                            editorOptions={{ placeholder: 'Большой текст' }}>

                            <Label text={"Большой текст"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="smallText"
                            editorType="dxTextArea"
                            editorOptions={{ placeholder: 'Маленький текст' }}>

                            <Label text={"Маленький текст"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="adress"
                            editorType="dxTextArea"
                            editorOptions={{ placeholder: 'Адрес' }}>

                            <Label text={"Адрес"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="phone"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Номер телефна' }}>

                            <Label text={"Номер телефна"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="email"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'E-mail' }}>

                            <Label text={"E-mail"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="dolgota"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Долгота' }}>

                            <Label text={"Долгота"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="shirota"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Широта' }}>

                            <Label text={"Широта"} />
                        </SimpleItem>
                        <ButtonItem buttonOptions={{ text: "Сохранить", onClick: async () => await updateBaseInfoHandler() }} />
                    </GroupItem>
                </Form>
            </div>

        </div>
    )
}
export default InfoRedactor