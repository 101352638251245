import { Button } from "devextreme-react"
import { useEffect, useState } from "react"
import { MdCatchingPokemon } from "react-icons/md"
import downloadPdfService from "../services/downloadPdfService"
import downloadSignedPdfService from "../services/downloadSignedPdfService"
import downloadSignatureService from "../services/downloadSignatureService"
import sendFinishRequest from "../services/sendFinishRequest"
import SignDocumentPopup from "./SignDocumentPopup"
import downloadDopFiles from "../services/downloadDopFiles"
import deleteRequestService from "../services/deleteRequestService"
import { HiArchiveBoxXMark } from "react-icons/hi2";

const RequestImage = ({ request, setVisibleLoading, handlePopapNotification, updateRequests, auth }) => {

    const [currentRequest, setCurrentRequest] = useState(request?.request)
    const [currentMessages, setCurrentMessages] = useState(request?.messages)
    const [hasDopFiles, setHasDopFiles] = useState(false)
    const [displayRequestStatus, setDisplayRequestStatus] = useState('В процессе')
    const [displayRequestStatusColor, setDisplayRequestStatusColor] = useState('Black')
    const [displayRequestKind, setDisplayRequestKind] = useState('Вид договора не указан')
    const [displayUserType, setDisplayUserType] = useState(null)
    const [displayDate, setDisplayDate] = useState('Дата не указана')
    const [visibleSignPopup, setVisibleSignPopup] = useState(false)

    useEffect(() => {
        setCurrentRequest(request?.request)
        setCurrentMessages(request?.messages)
        setHasDopFiles(request?.hasDopFiles ?? false)
        initDisplay(request?.request)
    }, [request])

    const initDisplay = (newRequest) => {
        if (newRequest?.status) {
            if (newRequest?.status === 2) {
                setDisplayRequestStatus("Заявка сформирована")
                setDisplayRequestStatusColor("DarkBlue")
            }
            else if (newRequest?.status === 3) {
                setDisplayRequestStatus("Заявка подписана")
                setDisplayRequestStatusColor("DarkBlue")
            }
            else if (newRequest?.status === 4) {
                setDisplayRequestStatus("Заявка отправлена")
                setDisplayRequestStatusColor("DarkOrange")
            }
            else if (newRequest?.status === 5) {
                setDisplayRequestStatus("Заявка одобрена")
                setDisplayRequestStatusColor("DarkGreen")
            }
            else if (newRequest?.status === 6) {
                setDisplayRequestStatus("Заявка отклонена")
                setDisplayRequestStatusColor("FireBrick")
            } else {
                setDisplayRequestStatus("Ошибка создания")
                setDisplayRequestStatusColor("FireBrick")
            }
        }
        else {
            setDisplayRequestStatus("Ошибка создания")
            setDisplayRequestStatusColor("FireBrick")
        }
        if (newRequest?.kind) {
            if (newRequest?.kind == 1) {
                setDisplayRequestKind("Заявка о предоставлении технических условий на технологическое присоединение")
            }
            else if (newRequest?.kind == 2) {
                setDisplayRequestKind("Заявка на заключение договора о подключении к системе теплоснабжения")
            } else {
                setDisplayRequestKind("Вид договора не указан")
            }
        }
        else {
            setDisplayRequestKind("Вид договора не указан")
        }

        if (newRequest?.userType) {
            if (newRequest?.userType == 1) {
                setDisplayUserType("Физ. лицо")
            }
            else if (newRequest?.userType == 2) {
                setDisplayUserType("ИП")
            }
            else if (newRequest?.userType == 3) {
                setDisplayUserType("Юр. лицо")
            }
            else {
                setDisplayUserType(null)
            }
        }
        else {
            setDisplayUserType(null)
        }

        if (newRequest?.createAt) {
            try {
                let options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                }

                let date = new Date(newRequest?.createAt)
                setDisplayDate(date.toLocaleDateString('ru-RU', options))
            }
            catch {
                setDisplayDate("Дата не указана")
            }
        }
        else {
            setDisplayDate("Дата не указана")
        }
    }

    const downloadPdfHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadPdfService(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `request-${currentRequest.requestNumber}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const deleteRequestHandler = async () => {
        setVisibleLoading(true)
        var result = await deleteRequestService(currentRequest.requestId, auth)
        if (result.success) {
            await updateRequests()
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadSignedPdfHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadSignedPdfService(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `request_signed-${currentRequest.requestNumber}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadSignatureHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadSignatureService(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `sign-${currentRequest.requestNumber}.p7s`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const downloadDopFilesHandler = async () => {
        setVisibleLoading(true)
        var result = await downloadDopFiles(currentRequest.requestId, auth)
        if (result.success) {
            const blobUrl = window.URL.createObjectURL(result.content);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `files-${currentRequest.requestNumber}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const sendRequestHandler = async () => {
        setVisibleLoading(true)
        var response = await sendFinishRequest(currentRequest.requestId, auth)
        if (response.success) {
            setCurrentRequestHandler(response.content)
            setVisibleLoading(false)
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    const openSignPopup = () => {
        setVisibleSignPopup(true)
    }

    const setCurrentRequestHandler = (newrequest) => {
        setCurrentRequest(newrequest)
        initDisplay(newrequest)
    }

    const normaliseDate = (date) => {
        try {
            let options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            }

            let dateObject = new Date(date)
            return dateObject.toLocaleDateString('ru-RU', options)
        }
        catch {
            return "Дата не указана"
        }
    }

    return (
        <div className="request-element-body">
            <SignDocumentPopup auth={auth} visible={visibleSignPopup} request={currentRequest} onHide={() => setVisibleSignPopup(false)} setRequest={setCurrentRequestHandler} />
            <div className="request-element-header">
                <div className="request-element-small">
                    <div className="request-element-header-text">Заявка № {currentRequest?.requestNumber ?? null}</div>
                    <div className="request-element-header-text">({displayUserType})</div>
                    <HiArchiveBoxXMark visibility={currentRequest?.status >= 4 ? 'collapse' : 'visible'} onClick={deleteRequestHandler} style={{ width: '15px', height: '15px', marginRight: '10px', color: 'red', cursor: 'pointer' }} />
                </div>
                <div className="request-element-status" style={{ borderColor: displayRequestStatusColor, color: displayRequestStatusColor }}>
                    {displayRequestStatus}
                </div>
            </div>
            
            <div style={{ marginBottom: '10px' }}>{displayRequestKind}</div>
            <div style={{ marginBottom: '10px' }}>{displayDate}</div>
            <div>
                {
                    currentMessages && currentRequest?.lenght !== 0 && (
                        currentMessages.map(c => (
                            <div style={{ marginBottom: '10px' }}>
                                <div className="request-message-header">
                                    <div>{normaliseDate(c?.createAt)}</div>
                                </div>
                                <div className="request-message-border">
                                    <div className="request-message-text">{c?.text}</div>
                                </div>
                            </div>
                        ))
                    )
                }

            </div>
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    <Button visible={currentRequest?.status == 2} onClick={downloadPdfHandler} text='Скачать заявку в PDF/A' />
                    <Button visible={currentRequest?.status >= 2 && currentRequest?.status < 4} onClick={openSignPopup} text= {currentRequest?.status >= 3 ? 'Переподписать документ' : 'Подписать документ'} />
                    <Button visible={currentRequest?.status >= 3} onClick={downloadSignedPdfHandler} text='Скачать подписанную заявку в PDF/A' />
                    <Button visible={currentRequest?.status >= 3} onClick={downloadSignatureHandler} text='Скачать открепленную подпись' />
                    <Button disabled={currentRequest?.status !== 3 || currentRequest?.status >= 4} onClick={sendRequestHandler} text='Отправить заявку' />
                </div>
                <Button visible={hasDopFiles} onClick={downloadDopFilesHandler} text='Скачать прикрепленные файлы' />
            </div>
        </div>
    )
}
export default RequestImage