import { Button } from "devextreme-react"

const DocumentButtonItem = ({document, deleteDocument, downloadFile}) => {
    return (
        <div>
            <div className="pages-button-document-border">
                <div style={{ marginLeft: '10px', marginBottom: '5px' }}>{document.name ?? "Название отсутствует"}</div>
                <div style={{marginBottom: '0px'}} className="pages-button-bottom">
                    <Button onClick={() => downloadFile(document)} text="Скачать документ" />
                    <Button onClick={() => deleteDocument(document.documentId)} text="Удалить документ" />
                </div>
            </div>
        </div>
    )
}

export default DocumentButtonItem