import { useNavigate } from "react-router-dom"
import Menu, { MenuTypes } from 'devextreme-react/menu';
import { Button } from "devextreme-react";
import Cookies from 'js-cookie';
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";

const AccountHeader = () => {
    const navigation = useNavigate()
    const auth = useAuth()
    const menuItems = [
        {
            name: "Данные профиля",
            route: "/account/profile"
        },
        {
            name: "Мои заявки",
            route: "/account/requests"
        },
        {
            name: "Создание заявки",
            items: [
                {
                    name: "Создание заявки на заключение договора технологического присоединения",
                    route: "/account/new-request-dogovor"
                },
                {
                    name: "Создание заявки на выдачу технических условий",
                    route: "/account/new-request-technical"
                }
            ]
        }
    ]

    useEffect(() => {
        const broadcastChannel = new BroadcastChannel('action_channel');
    
        function handleMessage(event) {
          if (event.data.type === 'ACTION_PERFORMED') {
            navigation('/')
          }
        }
    
        broadcastChannel.addEventListener('message', handleMessage);
    
        return () => {
          broadcastChannel.removeEventListener('message', handleMessage);
          broadcastChannel.close();
        };
      }, []);

    const onMenuClick = (e) => {
        if (e.itemData.route) {
            navigation(e.itemData.route)
        }
    }

    const logOut = () => {
        Cookies.remove('token');
        const broadcastChannel = new BroadcastChannel('action_channel');
        broadcastChannel.postMessage({ type: 'ACTION_PERFORMED' });
        broadcastChannel.close();
    }

    return (
        <div className="header-menu-base">
            <Menu
                onItemClick={onMenuClick}
                dataSource={menuItems}
                displayExpr="name"
            />
            <Button onClick={logOut} text={`${auth.username} Выйти`} />
        </div>
    )
}
export default AccountHeader