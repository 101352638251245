const downloadSignatureService = async (id, auth) => {
    try {
        const apiUrl = process.env.REACT_APP_ACCOUNT_DOWNLOAD_SIGN + id;
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + auth.token ?? "",
            },
        });

        let data = null

        let body = await response.blob()
        const newBlob = new Blob([body]);

        if (response.status === 200) {
            data = {
                success: true,
                message: `успешное получение файла`,
                content: newBlob
            }
        }
        else if (response.status === 400) {
            data = {
                success: false,
                message: `Не удалось скачать файл`,
                content: null
            }
        }
        else {
            data = {
                success: false,
                message: "Ошибка сервера при скачивании файла",
                content: null
            }
        }

        return data

    } catch (error) {
        let data = {
            success: false,
            message: "Не удается подключиться к серверу",
            content: null
        }
        return data
    }
}

export default downloadSignatureService