import { useState, useEffect } from "react"
import NewsList from "./components/NewsList"
import "./css/news-style.css"
import MainHeader from "../../../base-components/MainHeader"
import AdminHeader from "../../../base-components/AdminHeader"
import { LoadPanel, Toast } from 'devextreme-react';
import CurrentNewsCreator from "./components/CurrentNewsCreator"
import getCurrentNews from "./services/getCurrentNews"
import getDisplayNews from "./services/displayNews"
import { useAuth } from "../../../hooks/useAuth"

const NewsReadtor = () => {

    const initNews = {
        news: [],
        totalCount: 0
    }

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)
    const [selectedNews, setSelectedNews] = useState(null)
    const [currentNewsList, setCurrentNewsList] = useState(initNews)
    const auth = useAuth()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const loadCurrentNews = async (current) => {
        if (current !== null && current?.newsId !== null) {
            setVisibleLoading(true)
            var news = await getCurrentNews(current?.newsId, auth)
            if (news.success) {
                setSelectedNews(news.content)
            } else {
                handlePopapNotification(news.message)
            }
            setVisibleLoading(false)
        }
    }

    const onSelectedHandle = (current) => {
        loadCurrentNews(current)
    }

    const updateNewsList = async () => {
        setVisibleLoading(true)
        var news = await getDisplayNews(1, auth)
        if (news.success) {
            setCurrentNewsList(news.content)
            setSelectedNews(null)
        } else {
            handlePopapNotification(news.message)
        }
        setVisibleLoading(false)
    }

    useEffect(() => {
        document.title = "Редактор новостей"
        updateNewsList()
    }, [])

    return (
        <div >
            <MainHeader />
            <AdminHeader />
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <div className="news-main-container">
                <div className="list-container">
                    <NewsList updateList={updateNewsList} newsListCurrent={currentNewsList} setVisibleLoading={setVisibleLoading} handlePopapNotification={handlePopapNotification} setSelectedNews={onSelectedHandle} />
                </div>
                <div className="redactor-container">
                    <h3>Редактор новостей</h3>
                    {
                        selectedNews !== null ? (
                            <div>
                                <CurrentNewsCreator updateList={updateNewsList} setVisibleLoading={setVisibleLoading} handlePopapNotification={handlePopapNotification} current={selectedNews} />
                            </div>
                        ) : (
                            <div>
                                Новость не выбрана
                            </div>
                        )
                    }
                </div>
            </div>

        </div>
    )
}
export default NewsReadtor