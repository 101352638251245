import { Popup, Button, LoadPanel, Toast } from "devextreme-react"
import { Form, SimpleItem, GroupItem, Label, ButtonItem, RequiredRule } from 'devextreme-react/form';
import { useEffect, useState } from "react";
import sendMailService from "../services/sendMailService"

const MailMessage = ({ onHide, visible, setVisibleLo }) => {

    const initMessage = {
        theme: "",
        messageBody: "",
        object: "",
        email: "",
        phone: ""
    }

    useEffect(() => {
        if (visible === true) {
            setMessage(initMessage)
            setErrorData([])
        }
    }, [visible])

    const [message, setMessage] = useState(null)

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupSuccessNotification, setPopupSuccessNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const [errorData, setErrorData] = useState([])

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const sendMessageHandler = async () => {
        let errors = await validateForm()
        setErrorData(errors)
        if (errors !== null && errors?.length !== 0) {
            return
        }
        setVisibleLoading(true)
        var result = await sendMailService(message)
        if (result.success) {
            setPopupSuccessNotification(true)
            onHide()
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const isEmptyOrSpaces = (str) => {
        return str === null || str?.match(/^ *$/) !== null;
    }

    const validateForm = async () => {
        let errors = []
        if (isEmptyOrSpaces(message?.theme)) {
            errors.push("Не указан тема обращения")
        }
        if (isEmptyOrSpaces(message?.messageBody)) {
            errors.push("Не указано сообщение")
        }
        if (isEmptyOrSpaces(message?.object)) {
            errors.push("Не указано ФИО")
        }
        if (isEmptyOrSpaces(message?.email)) {
            errors.push("Не указан E-mail")
        }
        if (isEmptyOrSpaces(message?.phone)) {
            errors.push("Не указан номер телефона")
        }
        return errors
    }

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={3000}
                onHiding={() => setPopupNotification(false)}
            />
            <Toast
                visible={popupSuccessNotification}
                message={"Успешная отправка"}
                type={'success'}
                displayTime={3000}
                onHiding={() => setPopupSuccessNotification(false)}
            />
            <Popup
                dragEnabled={false}
                title="Обращение"
                onHiding={onHide}
                visible={visible}
                enableBodyScroll={false}
                width={'600'}
                height={'auto'}
            >
                <Form formData={message}>
                    <SimpleItem
                        dataField="theme"
                        editorType="dxTextBox"
                        editorOptions={{ placeholder: 'Тема' }}>
                        <Label text={"Тема обращения"} />
                    </SimpleItem>
                    <SimpleItem
                        dataField="messageBody"
                        editorType="dxTextArea"
                        editorOptions={{ placeholder: 'Сообщение', height: 150 }}>
                        <Label text={"Сообщение"} />
                    </SimpleItem>
                    <SimpleItem
                        dataField="object"
                        editorType="dxTextBox"
                        editorOptions={{ placeholder: 'Ваше ФИО' }}>
                        <Label text={"Ваше ФИО"} />
                    </SimpleItem>
                    <SimpleItem
                        dataField="email"
                        editorType="dxTextBox"
                        editorOptions={{ placeholder: 'E-mail' }}>
                        <Label text={"E-mail"} />
                    </SimpleItem>
                    <SimpleItem
                        dataField="phone"
                        editorType="dxTextBox"
                        editorOptions={{ placeholder: 'Телефон' }}>

                        <Label text={"Телефон"} />
                    </SimpleItem>
                    <ButtonItem buttonOptions={{ text: "Отправить", onClick: sendMessageHandler }} />
                </Form>
                {
                    errorData?.length !== 0 &&
                    <div style={{ marginTop: '10px', justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                        {
                            errorData.map(c => (
                                <div style={{ color: 'red' }}>{c}</div>
                            ))
                        }
                    </div>
                }
            </Popup>
        </div>
    )
}

export default MailMessage