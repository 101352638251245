import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

export const useAuth = () => {

    let answer = {
        isAuth: false,
        kind: 0,
        userId: null,
        role: 0,
        token: null,
        username: null
    }

    let tokenCookie = Cookies.get('token');
    let token = null
    if (tokenCookie){
        token = JSON.parse(tokenCookie)
        if (token?.token){
            try{
                let decoded = jwtDecode(token.token)
                let dateTo = new Date(decoded?.exp * 1000);
                let dateFrom = new Date(decoded?.nbf * 1000);
                let now = new Date()

                if (now >= dateFrom && now < dateTo){
                    answer.isAuth = !!token?.token
                    answer.kind = Number(decoded?.kind ?? "0")
                    answer.userId = decoded?.aud
                    answer.token = token?.token ?? null
                    answer.username = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] ?? null

                    let startRole = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                    if (startRole){
                        if (startRole === 'b512d97e7cbf97c273e4db073bbb547aa65a84589227f8f3d9e4a72b9372a24d'){
                            answer.role = 1
                        }
                        else if (startRole === 'c1c224b03cd9bc7b6a86d77f5dace40191766c485cd55dc48caf9ac873335d6f'){
                            answer.role = 2
                        }
                        else if (startRole === '8b2085f74dfa9c78a23b7d573c23d27d6d0b0e50c82a9b13138b193325be3814'){
                            answer.role = 3
                        }
                    }
                }
            }
            catch{

            }
        }
    }
    return answer
}