import { useNavigate } from "react-router-dom"
import Menu, { MenuTypes } from 'devextreme-react/menu';
import { Button } from "devextreme-react";
import Cookies from 'js-cookie';
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";

const AdminHeader = () => {
    
    const navigation = useNavigate()
    const auth = useAuth()
    const menuItems = [
        {
            name: "Основная информация",
            route: "/admin/info"
        },
        {
            name: "Разделы",
            route: "/admin/pages"
        },
        {
            name: "Аккаунты пользователей",
            route: "/admin/roles"
        },
        {
            name: "Редактор новостей",
            route: "/admin/news"
        },
        {
            name: "Редактор политики конфиденциальности",
            route: "/admin/privacy-policy"
        },
        {
            name: "Файлы",
            route: "/admin/files"
        }
    ]

    const onMenuClick = (e) => {
        navigation(e.itemData.route)
    }

    const logOut = () => {
        Cookies.remove('token');
        const broadcastChannel = new BroadcastChannel('action_channel');
        broadcastChannel.postMessage({ type: 'ACTION_PERFORMED' });
        broadcastChannel.close();
    }

    useEffect(() => {
        const broadcastChannel = new BroadcastChannel('action_channel');
    
        function handleMessage(event) {
          if (event.data.type === 'ACTION_PERFORMED') {
            navigation('/')
          }
        }
    
        broadcastChannel.addEventListener('message', handleMessage);
    
        return () => {
          broadcastChannel.removeEventListener('message', handleMessage);
          broadcastChannel.close();
        };
      }, []);
    
    return (
        <div className="header-menu-base">
            <Menu
                onItemClick={onMenuClick}
                dataSource={menuItems}
                displayExpr="name"
            />
            <Button onClick={logOut} text={`${auth.username} Выйти`} />
        </div>
    )
}

export default AdminHeader