import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const AccountPrivateRoute = ({element}) => {

    const auth = useAuth()

    return (
        auth.isAuth && auth.role === 1 ? (
          element
        ) : (
          <Navigate to="/" />
        )
      );

}

export default AccountPrivateRoute