import AdminHeader from "../../../base-components/AdminHeader"
import MainHeader from "../../../base-components/MainHeader"
import IndexPages from "./components/IndexPages"
import { useState, useEffect, useRef } from "react"
import { LoadPanel, Toast } from 'devextreme-react';
import "./css/pages-style.css"
import createIndexPage from "./services/createIndexPage";
import displayIndexPages from "./services/displayIndexPages";
import reorderIndexPages from "./services/reorderIndexPages";
import IndexContent from "./components/IndexContent";
import deleteIndexPage from "./services/deleteIndexPage";
import { useAuth } from "../../../hooks/useAuth";

const PagesPage = () => {

    const [indexPagesList, setIndexPagesList] = useState([])
    const [currentIndexPage, setCrrentIndexPage] = useState(null)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const auth = useAuth()

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const onSelectedHandle = (current) => {
        setCrrentIndexPage(current)
    }

    const createOrUpdateIndexPage = async (body) => {
        setVisibleLoading(true)
        var result = await createIndexPage(body, auth)
        if (result.success) {
            await updateIndexPageList()
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const deleteIndexPageHandler = async (id) => {
        setVisibleLoading(true)
        var result = await deleteIndexPage(id, auth)
        if (result.success) {
            await updateIndexPageList()
            setCrrentIndexPage(null)
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const updateIndexPageList = async () => {
        setVisibleLoading(true)
        var result = await displayIndexPages(auth)
        if (result.success) {
            setIndexPagesList(result.content)
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    const reorderPages = async (body) => {

        setVisibleLoading(true)
        var result = await reorderIndexPages(body, auth)
        if (result.success) {
            await updateIndexPageList()
        } else {
            handlePopapNotification(result.message)
        }
        setVisibleLoading(false)
    }

    useEffect(() => {
        document.title = "Разделы"
        updateIndexPageList()
    }, [])

    return (
        <div>
            <MainHeader />
            <AdminHeader />
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <div className="pages-body-container">

                <div className="list-container">
                    <IndexPages 
                    reorder={reorderPages} 
                    indexPages={indexPagesList} 
                    createIndexPage={createOrUpdateIndexPage} 
                    setSelected={onSelectedHandle} 
                    />
                </div>
                <div className="redactor-page-container">
                    <h3>Содержание</h3>
                    {
                        currentIndexPage ? (
                            <IndexContent 
                            indexPage={currentIndexPage} 
                            setLoading={setVisibleLoading} 
                            notification={handlePopapNotification} 
                            updateIndexPage={createOrUpdateIndexPage}
                            deleteIndexPage={deleteIndexPageHandler}/>
                        ):(
                            <div>Ничего не вырано</div>
                        )
                    }
                </div>

            </div>

        </div>
    )
}

export default PagesPage