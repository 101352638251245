import { Button, CheckBox, Popup, RadioGroup, SelectBox } from "devextreme-react"
import { Form, SimpleItem, GroupItem, Label, ButtonItem, RequiredRule, EmailRule } from 'devextreme-react/form';
import { useEffect, useRef, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import { LoadPanel, Toast } from 'devextreme-react';
import CryptoJS from 'crypto-js';
import updateAccountService from "../services/updateAccountService";
import regAccountByAdmin from "../services/regAccountByAdmin";

const RegisterUserPopup = ({ registerData, visible, onHide, updateList, deleteAccount, auth }) => {

    const regDataInit = {
        kind: 1,
        role: 1,
        inn: "",
        ogrnip: "",
        ogrn: "",
        username: "",
        password: "",
        password2: ""
    }

    const authTypes = [
        {
            name: 'Физ. лицо',
            type: 1,
        },
        {
            name: 'ИП',
            type: 2,
        },
        {
            name: 'Юр. лицо',
            type: 3,
        }
    ]

    const roles = [
        {
            name: "Пользователь",
            type: 1
        },
        {
            name: "Администратор",
            type: 2
        },
        {
            name: "Менеджер",
            type: 3
        }
    ]
    const [selectedAuthType, setSelectedAuthType] = useState(authTypes[0].type)
    const [selectedRole, setSelectedRole] = useState(registerData?.role ?? roles[0].type)
    const [errorData, setErrorData] = useState([])
    const [currentRegData, setCurrentRegData] = useState(registerData)

    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const [modeRegButton, setModeRegButton] = useState('password')

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    useEffect(() => {
        if (visible === true) {
            if (!registerData?.kind) {
                setSelectedAuthType(authTypes[0].type)
            } else {
                setSelectedAuthType(registerData.kind)
            }

            if (!registerData?.role) {
                setSelectedRole(roles[0].type)
            } else {
                setSelectedRole(registerData.role)
            }
            setCurrentRegData(registerData)
        }
    }, [registerData, visible])

    const showRegButton = () => {
        if (modeRegButton === 'password') {
            setModeRegButton('text')
        } else {
            setModeRegButton('password')
        }
    }

    const HidePopup = () => {
        setSelectedAuthType(1)
        setErrorData([])
        setModeRegButton('password')
        setSelectedRole(1)
        setCurrentRegData(regDataInit)
        onHide()
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const checkPasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength += 1; // Минимальная длина 8 символов
        if (/[A-Z]/.test(password)) strength += 1; // Наличие заглавных букв
        if (/[a-z]/.test(password)) strength += 1; // Наличие строчных букв
        if (/\d/.test(password)) strength += 1; // Наличие цифр
        if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) strength += 1; // Наличие специальных символов
        return strength;
    };

    const validateRegFormNew = async () => {

        let errors = []
        if (selectedRole === 1) {
            if (selectedAuthType === 1) {
                if (isEmptyOrSpaces(currentRegData?.inn)) {
                    errors.push("Не указан ИНН")
                }
            }
            else if (selectedAuthType === 2) {
                if (isEmptyOrSpaces(currentRegData?.orgnip)) {
                    errors.push("Не указан ОГРНИП")
                }
            }
            else if (selectedAuthType === 3) {
                if (isEmptyOrSpaces(currentRegData?.ogrn)) {
                    errors.push("Не указан ОГРН")
                }
            }
            else {
                errors.push("Не указан тип учетной записи")
            }
        }
        if (isEmptyOrSpaces(currentRegData?.username)) {
            errors.push("E-mail не может быть пустым")
        } else {
            let validMail = validateEmail(currentRegData?.username)
            if (!validMail) {
                errors.push("Неверно указан E-mail")
            }
        }
        if (isEmptyOrSpaces(currentRegData?.password)) {
            errors.push("Пароль не может быть пустым")
        } else {
            let stPassword = checkPasswordStrength(currentRegData?.password)
            if (stPassword < 5) {
                errors.push("Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы")
            }
        }
        if (currentRegData?.password !== currentRegData?.password2) {
            errors.push("Пароли не совпадают")
        }

        return errors
    }

    const validateRegForm = async () => {
        let errors = []
        if (selectedRole === 1) {
            if (selectedAuthType === 1) {
                if (isEmptyOrSpaces(currentRegData?.inn)) {
                    errors.push("Не указан ИНН")
                }
            }
            else if (selectedAuthType === 2) {
                
                if (isEmptyOrSpaces(currentRegData?.orgnip)) {
                    errors.push("Не указан ОГРНИП")
                }
            }
            else if (selectedAuthType === 3) {
                if (isEmptyOrSpaces(currentRegData?.ogrn)) {
                    errors.push("Не указан ОГРН")
                }
            }
            else {
                errors.push("Не указан тип учетной записи")
            }
        }

        if (isEmptyOrSpaces(currentRegData?.username)) {
            errors.push("E-mail не может быть пустым")
        } else {
            let validMail = validateEmail(currentRegData?.username)
            if (!validMail) {
                errors.push("Неверно указан E-mail")
            }
        }
        if (isEmptyOrSpaces(currentRegData?.password) && isEmptyOrSpaces(currentRegData?.password2)) {
        }
        else if (isEmptyOrSpaces(currentRegData?.password)) {
            errors.push("Пароль не может быть пустым")
        }
        else {
            let stPassword = checkPasswordStrength(currentRegData?.password)
            if (stPassword < 5) {
                errors.push("Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы")
            }
        }
        if (currentRegData?.password !== currentRegData?.password2) {
            errors.push("Пароли не совпадают")
        }

        return errors
    }

    const isEmptyOrSpaces = (str) => {
        return str === null || str?.match(/^ *$/) !== null;
    }

    const onRadioButtonChanged = (e) => {
        setSelectedAuthType(e.value)
    }

    const onRoleChanged = (e) => {
        setSelectedRole(e.value)
    }

    const regUserCore = async () => {
        if (currentRegData?.id) {
            let errors = await validateRegForm()
            setErrorData(errors)
            if (errors !== null && errors?.length !== 0) {
                return
            }
            setVisibleLoading(true)
            let hash = ""
            if (!isEmptyOrSpaces(currentRegData.password)) {
                hash = CryptoJS.SHA256(currentRegData.password).toString();
            }
            let body = {
                id: currentRegData.id,
                username: currentRegData.username,
                password: hash,
                ogrn: selectedAuthType === 3 && selectedRole === 1 ? currentRegData.ogrn : "",
                orgnip: selectedAuthType === 2 && selectedRole === 1 ? currentRegData.orgnip : "",
                inn: selectedAuthType === 1 && selectedRole === 1 ? currentRegData.inn : "",
                kind: selectedRole === 1 ? selectedAuthType : 0,
                role: selectedRole
            }

            var response = await updateAccountService(body, auth)
            if (response.success) {
                HidePopup()
                updateList()
            }
            else {
                handlePopapNotification(response.message)
            }
        }
        else {
            let errors = await validateRegFormNew()
            setErrorData(errors)
            if (errors !== null && errors?.length !== 0) {
                return
            }
            const hash = CryptoJS.SHA256(currentRegData.password).toString();
            let body = {
                username: currentRegData.username,
                password: hash,
                ogrn: selectedAuthType === 3 ? currentRegData.ogrn : "",
                orgnip: selectedAuthType === 2 ? currentRegData.orgnip : "",
                inn: selectedAuthType === 1 ? currentRegData.inn : "",
                kind: selectedAuthType
            }

            var response = await regAccountByAdmin(body, selectedRole, auth)
            if (response.success) {
                HidePopup()
                updateList()
            }
            else {
                handlePopapNotification(response.message)
            }
        }

        setVisibleLoading(false)
    }

    const onDeleteAccount = async () => {
        await deleteAccount(currentRegData?.id)
    }

    return (
        <div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            <Popup
                dragEnabled={false}
                visible={visible}
                onHiding={HidePopup}
                title={"Пользователь"}
                width={'600'}
                height={'auto'}
                enableBodyScroll={false}

            >
                <div>
                    <div style={{ marginBottom: '10px' }}>
                        <p>Выберите тип пользователя</p>
                        <RadioGroup
                            id='type'
                            valueExpr="type"
                            items={authTypes}
                            displayExpr='name'
                            layout="horizontal"
                            onValueChanged={onRadioButtonChanged}
                            value={selectedAuthType}
                        />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <p>Выберите роль пользователя</p>
                        <SelectBox placeholder="Выберите роль" onValueChanged={onRoleChanged} dataSource={roles} valueExpr="type" displayExpr="name" value={selectedRole} />
                    </div>

                    <Form formData={currentRegData}>
                        <SimpleItem
                            dataField="inn"
                            visible={selectedAuthType == 1}
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'ИНН' }}>

                            <Label text={"ИНН"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="orgnip"
                            visible={selectedAuthType == 2}
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'ОГРНИП' }}>

                            <Label text={"ОГРНИП"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="ogrn"
                            visible={selectedAuthType == 3}
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'ОГРН' }}>

                            <Label text={"ОГРН"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="username"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'E-mail' }}>

                            <Label text={"E-mail"} />
                            <EmailRule message="Неверно введен E-mail" />
                        </SimpleItem>
                        <SimpleItem
                            dataField="password"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Оставить без изменений', mode: modeRegButton, buttons: [{ name: "password", location: 'after', options: { icon: 'eyeopen', stylingMode: 'text', onClick: showRegButton } }] }}>

                            <Label text={"Пароль"} />
                        </SimpleItem>
                        <SimpleItem
                            dataField="password2"
                            editorType="dxTextBox"
                            editorOptions={{ placeholder: 'Оставить без изменений', mode: modeRegButton, buttons: [{ name: "password", location: 'after', options: { icon: 'eyeopen', stylingMode: 'text', onClick: showRegButton } }] }}>

                            <Label text={"Повторите пароль"} />
                        </SimpleItem>
                    </Form>
                    <div className="login-button-auth">
                        {
                            errorData?.length !== 0 &&
                            <div style={{ marginTop: '10px', justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                                {
                                    errorData.map(c => (
                                        <div style={{ color: 'red' }}>{c}</div>
                                    ))
                                }
                            </div>
                        }
                        <Button onClick={regUserCore} className="login-item-auth" text="Сохранить" />
                        <Link onClick={onDeleteAccount} style={{ color: 'red' }} className="login-item-auth">Удалить аккаунт</Link>
                    </div>

                </div>

            </Popup>
        </div>
    )
}

export default RegisterUserPopup