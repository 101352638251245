import { Button, List } from "devextreme-react"
import "../css/news-style.css"
import { useState, useEffect, useRef } from "react"
import createOrUpdateNews from "../services/createOrUpdateNews"
import getDisplayNews from "../services/displayNews"
import { useAuth } from "../../../../hooks/useAuth"

const NewsList = ({updateList, newsListCurrent, setVisibleLoading, handlePopapNotification, setSelectedNews }) => {

    const initNews = {
        news: [],
        totalCount: 0
    }
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    const listRef = useRef()
    const auth = useAuth()

    const [currentPage, setCurrentPage] = useState(1)
    const [newsList, setNewsList] = useState(initNews)

    const createNewsHandler = async () => {
        setVisibleLoading(true)
        var response = await createOrUpdateNews({ status: 0 }, auth)
        if (response.success) {
            updateList()
        }
        else {
            handlePopapNotification(response.message)
        }
        setVisibleLoading(false)
    }

    useEffect(() => {
        setCurrentPage(1)
        setNewsList(newsListCurrent)
    }, [newsListCurrent])

    const loadMorePages = async() => {
        setVisibleLoading(true)
        var news = await getDisplayNews(currentPage + 1, auth)
        if (news.success) {
            let oldValue = newsList
            if (news.content !== null && news?.news !== null){
                oldValue.news = oldValue.news.concat(news.content.news)
                setCurrentPage(currentPage + 1)
                setNewsList(oldValue)
            }
            
        } else {
            handlePopapNotification(news.message)
        }
        setVisibleLoading(false)
    }

    const itemRender = (e) => {
        let date = new Date(e.createAt)
        return (
            <div>
                <div style={{wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal'}}>{e.name ?? "Без названия"}</div>
                <div>{date.toLocaleDateString('ru-RU', options)}</div>
                {
                    e.status === 1 ? (
                        <div className="news-item-status">Статус: <div style={{ color: 'green', marginLeft: '10px' }}>Опубликовано</div></div>
                    ) : (
                        <div className="news-item-status">Статус: <div style={{ color: 'red', marginLeft: '10px' }}>Не опубликовано</div></div>
                    )
                }
            </div>
        )
    }

    const onSelectionChanged = (e) => {
        if (e.addedItems?.length !== 0){
            setSelectedNews(e.addedItems[0])
        }else{
            setSelectedNews(null)
        }
    }

    return (
        <div>
            <h3>Список новостей</h3>
            <Button onClick={createNewsHandler} style={{ marginBottom: '10px', width: '100%' }} text="Создать новую запись" />
            <List
                ref={listRef}
                dataSource={newsList.news}
                itemRender={itemRender}
                noDataText="Новости отсутствуют"
                pageLoadMode="scrollBottom"
                onSelectionChanged={onSelectionChanged}
                selectByClick={true}
                selectionMode={'single'}
            />
            {
                newsList !== null && newsList?.news.length !== 0 && newsList?.news?.length < newsList?.totalCount && (
                    <Button onClick={loadMorePages} style={{ marginTop: '10px', width: '100%' }} text="Загрузить еще" />
                )
            }

        </div>

    )
}

export default NewsList