import { Popup, FileUploader, Button } from "devextreme-react"
import { useRef, useState } from "react";
import { LoadPanel, Toast } from 'devextreme-react';

const UploadRequestFile = ({onHide, visible, addRequestFile}) => {

    const uploadRef = useRef()
    const [selectedFile, setSelectedFile] = useState(null)
    const [visibleLoading, setVisibleLoading] = useState(false)
    const [popupNotification, setPopupNotification] = useState(false)
    const [popupNotificationMessage, setPopupNotificationMessage] = useState(null)

    const handlePopapNotification = (message) => {
        if (message) {
            setPopupNotificationMessage(message)
            setPopupNotification(true)
        }
    }

    const handleFileChange = (e) => {
        setSelectedFile(e.value[0])
    };

    const onClose = () => {
        if (uploadRef.current.instance) {
            uploadRef.current.instance.option('value', [])
        }
        setSelectedFile(null)
        onHide()
    }

    const uploadFileHandler = () => {
        addRequestFile(selectedFile)
        onClose()
    }


    return (
        <Popup
            dragEnabled={false}
            title="Загрузка файлов"
            onHiding={onClose}
            visible={visible}
            enableBodyScroll={false}
            width={'600'}
            height={'auto'}
            maxHeight={'90%'}>
            <div className="bordered-file-div">
                <FileUploader
                    ref={uploadRef}
                    multiple={false}
                    selectButtonText="Выберите файл"
                    uploadButtonText="Загрузить"
                    uploadMode="useForm"
                    onValueChanged={handleFileChange}
                    labelText="Или перетащите сюда"
                    readyToUploadMessage="Готово к загрузке"
                />
            </div>
            <LoadPanel
                visible={visibleLoading}
                message='Загрузка...' />
            <Toast
                visible={popupNotification}
                message={popupNotificationMessage}
                type={'error'}
                displayTime={2000}
                onHiding={() => setPopupNotification(false)}
            />
            {
                selectedFile && <Button onClick={uploadFileHandler} style={{ marginTop: '10px' }} text="Загрузить файл" />
            }

        </Popup>
    )
}

export default UploadRequestFile