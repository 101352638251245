import { useRef, useState, useEffect } from "react"
import SectionViewer from "./SectionViewer"
import { Popup } from "devextreme-react"

const PageViewer = ({ visible, onHide, page }) => {

    useEffect(() => {
        
    }, [page])

    if (page == null){
        return null
    }
    return (
        <Popup
            dragEnabled={false}
            visible={visible}
            onHiding={onHide}
            enableBodyScroll={false}
            title={page?.name ?? "Информация"}
            height={'95%'}
            fullScreen = {true}
        >
            <div>
                {
                    page !== null && page.sections.length !== 0 ? (
                        <div>
                            {
                                page.sections.map(c => (
                                    <SectionViewer section={c} />
                                ))
                            }
                        </div>
                    ):(
                        <div>Здесь пока ничего нет</div>
                    )
                }
            </div>
        </Popup>

    )
}

export default PageViewer