import { Button, TextBox } from "devextreme-react"
import { useState, useEffect, useRef } from "react"
import "../css/pages-style.css"
import CreatePagePopup from "./CreatePagePopup";
import createPage from "../services/createPage";
import displayPages from "../services/displayPages";
import deletePage from "../services/deletePage";
import reorderPages from "../services/reorderPages";
import PageContent from "./PageContent";
import { useAuth } from "../../../../hooks/useAuth";

const IndexContent = ({ setLoading, notification, updateIndexPage, indexPage, deleteIndexPage }) => {

    const [pagesList, setPagesList] = useState(null)
    const [choosedPage, setChoosedPage] = useState(null)
    const [indexPageName, setIndexPageName] = useState(null)
    const textBoxRef = useRef()

    const auth = useAuth()

    const [createPagePopupVisible, setCreatePagePopupVisible] = useState(false)

    const onIndexPageNameChanged = (e) => {
        setIndexPageName(e.value)
    }

    const createIndexPageHandler = () => {
        let body = indexPage
        body.name = indexPageName
        body.status = 0
        updateIndexPage(body)
    }

    const publishIndexPageHandler = () => {
        let body = indexPage
        body.name = indexPageName
        body.status = 1
        updateIndexPage(body)
    }

    const createOrUpdatePageHandler = async (body) => {
        body.indexPageId = indexPage.indexPageId
        setLoading(true)
        var result = await createPage(body, auth)
        if (result.success) {
            await updatePagesList()
        } else {
            notification(result.message)
        }
        setLoading(false)
    }

    const deletePageHandler = async (id) => {
        setLoading(true)
        var result = await deletePage(id, auth)
        if (result.success) {
            await updatePagesList()
            setChoosedPage(null)
        } else {
            notification(result.message)
        }
        setLoading(false)
    }

    const updatePagesList = async () => {
        setLoading(true)
        var result = await displayPages(indexPage.indexPageId, auth)
        if (result.success) {
            setPagesList(result.content)
        } else {
            notification(result.message)
        }
        setLoading(false)
    }

    const openPageManagment = async () => {
        await updatePagesList()
        setCreatePagePopupVisible(true)
    }

    const reorderPagesHandler = async (body) => {
        setLoading(true)
        var result = await reorderPages(body, auth)
        if (result.success) {
            await updatePagesList()
        } else {
            notification(result.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        setIndexPageName(indexPage.name)
        setChoosedPage(null)
    }, [indexPage])

    return (
        <div >
            <CreatePagePopup
                pageList={pagesList}
                visible={createPagePopupVisible}
                onHide={() => setCreatePagePopupVisible(false)}
                createOrUpdatePage={createOrUpdatePageHandler}
                deletePage={deletePageHandler}
                reorder={reorderPagesHandler}
                setChoosed={setChoosedPage}
            />
            {
                indexPage && (
                    <div >
                        <div style={{ marginBottom: '10px' }}>
                            <div style={{ marginLeft: '10px' }}>Название раздела</div>
                            <TextBox ref={textBoxRef} onValueChanged={onIndexPageNameChanged} placeholder={indexPage?.name ?? "Без названия"} />
                        </div>
                        <div className="pages-button-bottom">
                            <div>
                                <Button onClick={createIndexPageHandler} text="Сохранить раздел как черновик" />
                                <Button style={{marginLeft: '10px'}} onClick={publishIndexPageHandler} text="Сохранить раздел и опубликовать" />
                                <Button style={{ marginLeft: '50px' }} text="Управление страницами" onClick={async () => await openPageManagment()} />
                            </div>
                            <Button onClick={() => deleteIndexPage(indexPage.indexPageId)} text="Удалить раздел" />
                        </div>
                        <hr />
                        {
                            choosedPage ? (
                                <div>
                                    <PageContent selectedPage={choosedPage} setLoading={setLoading} notofication={notification}/>
                                </div>
                            ) : (
                                <div>Страница для редактирования не выбрана. Для выбор воспользуйтесь "Управление страницами"</div>
                            )
                        }
                    </div>
                )
            }

        </div>
    )
}
export default IndexContent