const getHeaderInfo = async () => {
    try {
        const apiUrl = process.env.REACT_APP_HEADER_GET_INFO;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });
        let data = null
        if (response.status === 200){
          data = await response.json();
        }
        else if (response.status === 400) {
          data = {
            success: false,
            message: "Не удалось получить данные",
            content: null
          }
        } 
        else {
          data = {
            success: false,
            message: "Ошибка получения на сервере",
            content: null
          }
        }
  
        return data
  
      } catch (error) {
        let data = {
          success: false,
          message: "Не удается подключиться к серверу",
          content: null
        }
       return data
      }
}
export default getHeaderInfo